import React from 'react';

const XMarkIcon = ({ height = 10, width = 10, fillColor = 'var(--text-gray)' }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 10 10`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>cancel</title>
    <desc>Created with Sketch.</desc>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="13-Master-styles" transform="translate(-483.000000, -544.000000)" fill={fillColor}>
        <g id="cancel" transform="translate(483.000000, 544.000000)">
          <g id="Path">
            <path
              d="M5.73315789,4.9902729 L9.82668616,0.896744639 C10.0318129,0.691598441 10.0318129,0.359005848 9.82668616,0.153879142 C9.62153996,-0.0512670565 9.28892788,-0.0512670565 9.08380117,0.153879142 L4.99025341,4.24740741 L0.896725146,0.153879142 C0.691578947,-0.0512670565 0.358986355,-0.0512670565 0.153859649,0.153879142 C-0.0512670565,0.359025341 -0.0512865497,0.691617934 0.153859649,0.896744639 L4.24738791,4.9902729 L0.153859649,9.08380117 C-0.0512865497,9.28894737 -0.0512865497,9.62153996 0.153859649,9.82666667 C0.359005848,10.0318129 0.691598441,10.0317934 0.896725146,9.82666667 L4.99025341,5.7331384 L9.08376218,9.82666667 C9.28890838,10.0318129 9.62152047,10.0317934 9.82664717,9.82666667 C10.0317739,9.62152047 10.0317739,9.28892788 9.82664717,9.08380117 L5.73315789,4.9902729 Z"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default XMarkIcon;
