import { prod, beta } from '../theme/platforms';

export const prodPlatforms = prod;
export const betaPlatforms = beta;

export const maxFirstMessage = Object.freeze({
  default: 5000,
  facebook: 150,
  fbpage: 150,
  gads: 150,
  instagram: 150,
  linkedin: 150,
});

export const platformData = Object.freeze({
  FACEBOOK: {
    type: 'facebook',
    url: 'https://cdn.cashbot.ai/assets/13_facebook.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-facebook.svg',
    label: 'FACEBOOK AD',
    exclusive: true,
  },
  FACEBOOKPAGE: {
    type: 'fbpage',
    url: 'https://cdn.cashbot.ai/assets/facebook-page.svg',
    icon: '',
    label: 'FACEBOOK PAGE',
    exclusive: 'false',
  },
  WEBSITE: {
    type: 'web',
    url: 'https://cdn.cashbot.ai/assets/13_website.svg',
    label: 'WEBSITE',
    exclusive: false,
    onboarding: true,
  },
  INSTAGRAM: {
    type: 'instagram',
    url: 'https://cdn.cashbot.ai/assets/13_instagram.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-instagram.svg',
    label: 'INSTAGRAM',
    exclusive: true,
  },
  LINKEDIN: {
    type: 'linkedin',
    url: 'https://cdn.cashbot.ai/assets/13_linkedin.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-linkedin.svg',
    label: 'LINKEDIN',
    exclusive: true,
  },
  YOUTUBE: {
    type: 'youtube',
    url: 'https://cdn.cashbot.ai/assets/13_youtube.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-youtube.svg',
    label: 'YOUTUBE',
    exclusive: true,
  },
  EMAIL: {
    type: 'email',
    url: 'https://cdn.cashbot.ai/assets/13_email.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-email.svg',
    label: 'EMAIL',
    exclusive: true,
    onboarding: true,
  },
  SEM: {
    type: 'gads',
    url: 'https://cdn.cashbot.ai/assets/13_SEM.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-sem.svg',
    label: 'SEM',
    exclusive: true,
  },
  QRCODE: {
    type: 'qr',
    url: 'https://cdn.cashbot.ai/assets/13_qr-code.svg',
    icon: 'https://cdn.cashbot.ai/assets/trial-qr.svg',
    label: 'QR CODE',
    exclusive: true,
    onboarding: true,
  },
});

export const getDataByType = type => {
  const data = Object.keys(platformData).filter(obj => {
    return platformData[obj].type === type;
  });
  if (data.length === 1) {
    return platformData[data[0]];
  }
  return;
};
