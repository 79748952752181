import env from '../constants/env';
import { steps } from '../constants/onboardingSteps';
import Auth from '@aws-amplify/auth';
import uuid from 'uuid';

import { cardButton, initConversionFormData } from '../constants';

export function getUser() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (err) {
    console.log(err);
    return {};
  }
}

export function saveUser(data) {
  if (!data.attributes) {
    return false;
  }

  const user = data.attributes;
  const { username } = data;
  user.username = username;
  const prefix = data.keyPrefix;
  const str = `${prefix}.${username}.userData`;
  const info = JSON.parse(localStorage.getItem(str));

  if (!info) {
    return false;
  }

  info.UserAttributes.forEach(({ Name, Value }) => {
    if (Name === 'custom:onboarded') {
      user.onboardedNormalize = Value;
    }
  });

  localStorage.setItem('user', JSON.stringify(user));
  return user;
}

export function checkFlows(uuid, flows) {
  let found = false;
  for (var key in flows) {
    for (var idx in flows[key]) {
      const flow = flows[key][idx];
      if (flow.uuid === uuid) {
        found = true;
        break;
      }
    }
    if (found) {
      break;
    }
  }
  return found;
}

export async function postData(url = '', data = {}) {
  try {
    let res;
    if (data?.skipAuth) {
    } else {
      res = await Auth.currentSession();
    }
    const postResponse = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: res?.accessToken?.jwtToken || null,
      },
    });
    return postResponse;
  } catch (err) {
    return err;
  }
}

export function convertStep(st) {
  return st && parseInt(st) ? parseInt(st) : steps.FirstStep;
}

export function billingInterval(sub) {
  let theDate;
  const retVal = { status: 'active' };
  if (sub?.CancelAt > 0) {
    const cancelTime = sub.CancelAt * 1000;
    const now = Date.now();
    if (cancelTime <= now) {
      retVal.status = 'cancelled';
      return retVal;
    }
    retVal.status = 'expiring';
    theDate = new Date(cancelTime);
  } else if (!sub?.Anchor) {
    retVal.status = 'missing';
    return retVal;
  } else if (sub?.TrialStart > 0 && sub?.TrialEnd > 0) {
    const trialTime = sub.TrialEnd * 1000;
    const now = Date.now();
    if (trialTime >= now) {
      retVal.status = 'trial';
      theDate = new Date(trialTime);
    } else {
      theDate = new Date(sub.Anchor * 1000);
    }
  } else {
    theDate = new Date(sub.Anchor * 1000);
  }
  const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric' });
  const [{ value: mo }, , { value: da }, , { value: yr }] = dtf.formatToParts(theDate);
  retVal.mo = mo;
  retVal.da = da;
  retVal.yr = yr;
  const daN = parseInt(da);
  let dayString = `${daN}th`;
  let dayPrefix = 'the';
  if (sub.Interval === 'year') {
    dayPrefix = `${mo}`;
  }
  if (daN % 10 === 1 && daN !== 11) {
    dayString = `${daN}st`;
  } else if (daN % 10 === 2 && daN !== 12) {
    dayString = `${daN}nd`;
  } else if (daN % 10 === 3 && daN !== 13) {
    dayString = `${daN}rd`;
  }
  if (retVal.status === 'expiring') {
    retVal.message = `Your subscription expires on ${mo} ${dayString}, ${yr}.`;
  } else if (retVal.status === 'trial') {
    retVal.message = `After your free trial expires, you selected the ${sub.Name.toUpperCase()} plan at ${formatPrice(
      sub.Price,
      sub.Currency,
    )} per ${sub.Interval}.`;
  } else {
    retVal.message = `Your account will be billed on ${dayPrefix} ${dayString} of each ${sub.Interval}.`;
  }
  return retVal;
}

export function formatPrice(amount, currency, noDecimal) {
  let price = (amount / 100).toFixed(2);
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  if (noDecimal) {
    return numberFormat.format(price).replace(/\D00$/, '');
  }
  return numberFormat.format(price);
}

export async function refreshToken() {
  return Auth.currentAuthenticatedUser({ bypassCache: true })
    .then(user => {
      saveUser(user);
      return 'done';
    })
    .catch(err => {
      console.error('err1', err);
      return 'error';
    });
}

function parseLiveChat(val) {
  let phones = null;
  if (!val) {
    return { livechatType: null, livechat: null, livechatChannel: null, phones };
  }
  const valArr = val.split(':');
  if (valArr.length !== 2 && valArr.length !== 3) {
    return { livechatType: null, livechat: null, livechatChannel: null, phones };
  }
  if ((valArr[0] === 'mms' || valArr[0] === 'sms') && valArr.length === 3) {
    phones = valArr[2].split(',');
  }
  return { livechatType: valArr[0], livechat: valArr.slice(1, valArr.length).join(':'), livechatChannel: valArr.length === 3 ? valArr[2] : null, phones };
}

export function grabOnboardingInfo() {
  const user = getUser();
  const lc = parseLiveChat(user?.['custom:onboarding_livechat'] || null);
  if (user) {
    return {
      companyURL: user?.['custom:company_url'] ? user['custom:company_url'] : '',
      createdAt: user?.['custom:created_at'] ? user['custom:created_at'] : '',
      demoUUID: user?.['custom:demoUUID'] ? true : false,
      email: user?.email,
      flowUUID: user?.['custom:onboardingflow'],
      flowName: user?.['custom:onboardingflowname'],
      livechat: lc?.livechat,
      livechatChannel: lc?.livechatChannel,
      livechatType: lc?.livechatType,
      mms: user?.['custom:sms'] || null,
      msteams: user?.['custom:msteams'] || null,
      onboarded: user?.['custom:onboarded'],
      onboardedAccount: user?.['custom:onboarded_account'] || false,
      onboardedDashboard: user?.['custom:onboarded_dashboard'] || false,
      onboardedNormalize: user?.['onboardedNormalize'],
      onboardingTraining: user?.['custom:onboardingTraining'],
      onboardingType: user?.['custom:onboardingType'],
      partnerId: user?.['custom:partner_id'],
      partnerUrl: user?.['custom:partner_url'],
      partnerUsername: user?.['custom:partner_username'],
      pendingSubscription: user?.['custom:pending_subscription'] ? true : false,
      phone: user?.['phone_number'] || null,
      phones: lc?.phones,
      platform: user?.['custom:onboarding_template'] || null,
      privatePreview: user?.['custom:private_preview'] === "true" ? true : false,
      slack: user?.['custom:slack'] || null,
      slackChannel: user?.['custom:slack_channel'] || null,
      step: user?.['custom:onboardingstep'],
      subscriptions: user?.['custom:subscriptions'] ? true : false,
    };
  } else {
    return {};
  }
}

export function getUsername() {
  const user = getUser();

  return user?.username;
}

export function getPersonalInformation() {
  const user = getUser();

  return {
    company: user?.['custom:company'] ?? '',
    companySize: user?.['custom:company_size'] ?? '',
    companyURL: user?.['custom:company_url'] ?? '',
    createdAt: user?.['custom:created_at'] ? user['custom:created_at'] : '',
    email: user?.email ?? '',
    firstName: user?.given_name ?? '',
    lastName: user?.family_name ?? '',
    name: user?.username ?? '',
    partnerId: user?.['custom:partner_id'],
    partnerUrl: user?.['custom:partner_url'],
    phone: user?.['phone_number'] ?? '',
    privatePreview: user?.['custom:private_preview'] === "true" ? true : false,
    username: user?.username ? user.username : '',
  };
}

export function getIntegrations() {
  const user = getUser();

  return {
    intercom: user?.['custom:intercom'] ?? null,
    mailchimp: user?.['custom:mailchimp'] ?? null,
    marketo: user?.['custom:marketo'] ?? null,
    mms: user?.['custom:sms'] ?? null,
    msteams: user?.['custom:msteams'] ?? null,
    slack: user?.['custom:slack'] ?? null,
    slackChannel: user?.['custom:slack_channel'] ?? null,
    zapier: user?.['custom:zapier'] ?? null,
  };
}

export function getAccountInfo() {
  const user = getUser();
  return {
    company: user && user['custom:company'] ? user['custom:company'] : '',
    companySize: user && user['custom:company_size'] ? user['custom:company_size'] : '',
    companyURL: user && user['custom:company_url'] ? user['custom:company_url'] : '',
    createdAt: user?.['custom:created_at'] ? user['custom:created_at'] : '',
    email: user?.email || '',
    firstName: user?.given_name || '',
    lastName: user?.family_name || '',
    name: user?.name || '',
    partnerId: user?.['custom:partner_id'],
    partnerUrl: user?.['custom:partner_url'],
    partnerUsername: user?.['custom:partner_username'] || '',
    phone: user['phone_number'] || '',
    privatePreview: user?.['custom:private_preview'] === "true" ? true : false,
  };
}

export function obtainFacebookToken(successFn, mustLogin) {
  if (!successFn || !arguments.length) {
    return;
  }
  const FB = window.FB;
  if (!FB) {
    return 'Could not instantiate fb';
  }
  FB.getLoginStatus(response => {
    // Check if user is logged into fb
    if (response.status !== 'connected' || mustLogin) {
      try {
        // If user is not logged in, prompt them to log in
        FB.login(
          res => {
            // user is logged in, move forward
            successFn(res);
          },
          {
            scope: env.facebook.scope,
            auth_type: 'reauthenticate',
          },
        );
      } catch (err) {}
    } else {
      // user is logged in, move forward
      successFn(response);
    }
  });
}

export function isUrl(str) {
  // eslint-disable-next-line
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(str);
}

export const prepopulateCard = ({ meta }) =>
  meta.cards.map(({ title, desc: description, imageURL, buttons, value: cardURL, params }) => ({
    buttons: [...buttons, cardButton, cardButton, cardButton].slice(0, 3),
    cardURL,
    description,
    id: uuid.v4(),
    imageURL,
    title,
    params,
  }));

export function prepopulateConversion(data) {
  if (!data.meta || !data.meta.conversion) return initConversionFormData;
  const { conversion: conversionMeta } = data.meta;
  const normHeader = {
    key: '',
    value: '',
  };
  const prepopulateObj = {
    url: conversionMeta.url || '',
    enrich: conversionMeta.enrich || false,
    type: conversionMeta.method || 'POST',
    json: conversionMeta.payload || '{}',
    integration: conversionMeta.integration || 'custom',
    name: conversionMeta.name || '',
  };
  if (!conversionMeta.headers || (conversionMeta.headers && !conversionMeta.headers.length)) {
    for (let i = 0; i < 10; i++) {
      let str = 'header' + i;
      prepopulateObj[str] = normHeader;
    }
  } else if (conversionMeta.headers && conversionMeta.headers.length) {
    for (let i = 0; i < 10; i++) {
      let str = 'header' + i;
      let obj = {};
      if (conversionMeta.headers[i]) {
        obj.key = conversionMeta.headers[i]['key'];
        obj.value = conversionMeta.headers[i]['value'];
      } else {
        obj.key = '';
        obj.value = '';
      }

      prepopulateObj[str] = obj;
    }
  }
  return prepopulateObj;
}

export function prepopulateURL(data) {
  const conditionArr = [];
  if (data !== 'isNew' && data.Conditions?.length) {
    conditionArr.push(...data.Conditions);
  }

  while (conditionArr.length < 10) {
    conditionArr.push({ Type: 'Contains', Value: '' });
  }

  return {
    Conditions: conditionArr,
    Name: data.Name ?? '',
    URL: data.URL ?? '',
    WebID: data.WebID ?? '',
  };
}

export function genButtonGraph(btn, updateOrAddToGraph, isSecondStep) {
  const buttonId = uuid.v4();
  updateOrAddToGraph(
    {
      id: buttonId,
      label: btn.val,
      group: btn?.group ? btn.group : 'buttons',
      meta: {
        type: btn.selectType,
        value: btn.selectVal,
        params: btn.selectParams,
        categories: btn.selectCategories,
        submit: btn.selectSubmit,
      },
    },
    !btn.willBeCreated,
    isSecondStep,
  );
}

export function isHiddenLabel(txt) {
  if (txt === '(HIDDEN)' || txt === '(PAUSE FOR USER INPUT)') {
    return true;
  }

  return false;
}

export function cleanLabelForInput(txt) {
  if (txt === '(HIDDEN)') {
    return '(PAUSE FOR USER INPUT)';
  }

  return txt;
}

export function cleanLabelForSave(txt) {
  if (txt === '(PAUSE FOR USER INPUT)') {
    return '(HIDDEN)';
  }

  return txt;
}

export function cleanTxtForNodes(txt) {
  if (txt?.replaceAll) {
    return txt.replaceAll("\\n", "\n");
  }

  return txt;
}