import React from 'react';

import { Text } from '../components/AI/Common/Common';

export const redirectUrls = {};

export const userOnboardingTypes = {
  AI_ONBOARDING:        'aiOnboarding',
  DEFAULT_ONBOARDING:   'defaultOnboarding',
  WEB_CRAWL_ONBOARDING: 'webCrawlOnboarding',
  WORDPRESS_ONBOARDING: 'wordPressOnboarding',
}

export const THEME = {
  article: 'an EyeLevel',
  bubble: 'https://cdn.cashbot.ai/assets/chat_bubble_large.svg',
  calendar: 'https://calendly.com/olivierkatz',
  customization: {
    title: "WEBCHAT",
  },
  defaultContentLimit: 4,
  defaultPlan: {
    Aside: 'Up to 25 chat engagements a month.',
    Name: 'Basic Plan',
    Price: 'FREE',
    CTA: 'UPGRADE PLAN',
    Type: 'upgrade',
  },
  help: 'https://help.eyelevel.ai',
  logo: 'https://cdn.cashbot.ai/assets/ssp-logo-v2.svg',
  name: 'EyeLevel',
  nav: {
    demoSchedule: 'SCHEDULE A MEETING',
    hideChat: true,
    hideHelp: true,
    ignoreOnboarding: ['78ec21d6-4b9e-4134-8701-5ce963261041'],
  },
  partner_username: 'df2ba431-c508-4d8e-8fcd-27b2c5fd69e2',
  pricing: 'https://resources.eyelevel.ai/pricing',
  privacy: 'https://eyelevel.ai/privacy-policy',
  showSignup: function() {
    return false;
  },
  signUp: {
    cta: 'Request access.',
    cta_url: 'mailto:sales@eyelevel.ai',
    cta_prefix: 'Need an account?',
  },
  support: 'support@eyelevel.ai',
  templates: {
    onboardingID: 1,
  },
  terms: 'https://eyelevel.ai/terms-of-use',
  modals: {
    gpt3: {
      button: `BUILD A TEST VIRTUAL ASSISTANT`,
      gpt3Icon: 'https://cdn.cashbot.ai/qr-assets/23_gpt3 onboarding_icon_gpt3.svg',
      header:`Try the world's first ChatGPT powered virtual assistant`,
      text: `In this simple demo, you will create an AI-powered virtual assistant and then try it out, in just 2 steps!`,
    },
    welcome: {
      account: {
        l1: { show: true },
        l2: { show: true },
        l3: { show: true },
        l4: { show: true },
        l5: { show: true },
        l6: { show: true },
      },
      dashboard: {
        duplicate: 'Save time by duplicating flows. You can publish any duplicated flow to any platform.',
        publish: 'Access the link information for particular flows in one click',
      },
    },
  },
  pages: {
    account: {
      hidePlan: true,
    },
    ai: {
      help: {
        textNotEnough: `Our AI needs a minimum amout of content about your business to create a meaningful conversational experience for your users. 
        Please check that the url is correct, or add more urls below. Other useful content could include blogs and articles about your company on other websites.`
      },
      startPage: {
        header: 'EyeLevel.ai AI Virtual assistant demo',
        textHeader:'Enter a website url',
        text: 'We will create an AI-powered virtual assistant that can talk about the content on the page.',
        subtext: (<Text>Click <b>TRAIN ASSISTANT</b> to start the process.</Text>),
        button: 'TRAIN ASSISTANT',
      },
      creationPage: {
        header: 'Let’s get started.',
        header2Available: 'Claim it now!',
        header2Claimed: 'Create your own!',
        text: `What you created is a small fraction of what our platform can do for your company.
        Meet with us to discuss your particular business needs.`,
        title: 'Virtual Assistant Demo',
        button: `SCHEDULE A MEETING`,
        type: 'scheduler',
        error: {
          button: 'SCHEDULE A CONSULTATION',
          header: 'Issues creating an assistant?',
          header2: 'Let us help!',
          text: `Sometimes there are issues with crawling certain types of websites.
          Our team can help you resolve these issues.`,
        }
      }
    },
    dashboard: {
      createContentBtnTitle: 'NEW CONTENT',
      createGroupBtnTitle: 'NEW CONTENT GROUP',
      createProjectBtnTitle: 'NEW PROJECT',
      groupsTitle: 'Content',
      projectsTitle: 'Projects',
    },
    graph: {
    },
  },
};

export const grantAccess = (resource, sessionState) => {
  switch (resource) {
    case 'admin-signup':
      return sessionState?.AccountInfo?.AccessLevel >= 20;
    case 'annotation':
      return true;
    case 'dashboard-create-group-button':
      if (sessionState?.AccountInfo && sessionState?.Content) {
        const content = sessionState.Content.filter((cn) => {
          return cn?.Purpose === 'group';
        });
        const dflt = sessionState?.AccountInfo?.GroupLimit ? sessionState.AccountInfo.GroupLimit :
          THEME?.defaultGroupLimit ? THEME.defaultGroupLimit : 2;
        if (sessionState?.AccountInfo?.GroupLimit === 0 || content.length < dflt) {
          return true;
        }
      }
      return 'disabled';
    case 'new-project':
    case 'dashboard-create-project-button':
      if (sessionState?.AccountInfo && sessionState?.Subscriptions) {
        const dflt = sessionState?.AccountInfo?.ProjectLimit ? sessionState.AccountInfo.ProjectLimit : -1;
        if (sessionState?.AccountInfo?.ProjectLimit === 0 || sessionState.Subscriptions?.length < dflt) {
          return true;
        }
      }
      return 'disabled';
    case 'dashboard-create-content-button':
      if (sessionState?.AccountInfo && sessionState?.Content) {
        const content = sessionState.Content.filter((cn) => {
          return cn?.Purpose !== 'group';
        });
        const dflt = sessionState?.AccountInfo?.ContentLimit ? sessionState.AccountInfo.ContentLimit :
          THEME?.defaultContentLimit ? THEME.defaultContentLimit : 4;
        if (sessionState?.AccountInfo?.ContentLimit === 0 || content.length < dflt) {
          return true;
        }
      }
      return 'disabled';
    case 'flow-change-group':
      return true;
    default:
      return true;
  }
}

export const checkOnboardingUserType = (resource, userType) => {
  if (resource.demoUUID) {
    if (userType === userOnboardingTypes.WEB_CRAWL_ONBOARDING) {
      return true;
    } else if (userType === userOnboardingTypes.AI_ONBOARDING) {
      return true;
    }
  } else if (userType === userOnboardingTypes.DEFAULT_ONBOARDING) {
    return true;
  }

  return false;
};


// For now there is nothing disabled for ssp user without subscription
export const toolTipForDisabled = (resource) => {
  return "";
}
