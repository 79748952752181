import theme from './';

const users = {
  '5a14fd1d-6edd-46f8-beed-cc47dc7331dc': {
    logo: 'https://cdn.cashbot.ai/whitelabel/AskVet-logo-with-tag%402x-1.webp',
  }
};

export const whitelabel = (resource, userInfo) => {
  switch(resource) {
    case 'logo':
      if (users?.[userInfo?.username]?.logo) {
        return users[userInfo.username].logo;
      }

      return theme?.logo;
    default:
      return '';
  }
};
