import { useContext, useEffect } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { SessionContext } from '../../../context/SessionContext';

import { defaultPlanId } from '../../../utils/vendasta';

const VendastaBar = () => {
  const { isAuthed, isLoading } = useAuth();
  const { personalInfo, vPlanId } = useContext(SessionContext);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthed && personalInfo?.partnerId && personalInfo?.partnerUrl) {
        let vs = document.createElement('script');
        vs.src = "//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js";
        vs.setAttribute('data-url', personalInfo.partnerUrl);
        vs.setAttribute('data-account-id', personalInfo.partnerId);
        vs.setAttribute('data-app-id', vPlanId || defaultPlanId);
        vs.setAttribute('target-element-class', 'vendastaNav');
        const bd = document.getElementsByTagName('body');
        if (bd.length > 0) {
          bd[0].appendChild(vs);
        }
      }
    }
  }, [
    isAuthed,
    isLoading,
    personalInfo,
    vPlanId,
  ]);

  return null;
};

export default VendastaBar;
