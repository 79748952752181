export const prod = Object.freeze({
  WEBSITE: 'web',
  QRCODE: 'qr',
  EMAIL: 'email',
});

export const beta = Object.freeze({
  FACEBOOK: 'facebook',
  FACEBOOKPAGE: 'fbpage',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  SEM: 'gads',
});

export const integrations = Object.freeze({
  FACEBOOK: 'facebook',
  HUBSPOT: 'hubspot',
  LINKEDIN: 'linkedin',
  MAILCHIMP: 'mailchimp',
  MARKETO: 'marketo',
  SALESFORCE: 'salesforce',
  FACEBOOKPAGE: 'fbpage',
  SLACK: 'slack',
  SMS: 'mms',
  TEAMS: 'msteams',
  ZAPIER: 'zapier',
});

export const integrationDesc = Object.freeze({
  FACEBOOK: {
    type: 'Marketing',
    isPremium: true,
    label: 'Facebook Ad',
    value: integrations.FACEBOOK,
  },
  LINKEDIN: {
    type: 'Marketing',
    isPremium: true,
    label: 'LinkedIn',
    value: integrations.LINKEDIN,
  },
  TEAMS: {
    type: 'Live Chat',
    isPremium: true,
    label: 'Microsoft Teams',
    value: integrations.TEAMS,
  },
  SLACK: {
    type: 'Live Chat',
    isPremium: false,
    label: 'Slack',
    value: integrations.SLACK,
  },
  SMS: {
    type: 'Live Chat',
    isPremium: false,
    label: 'SMS',
    value: integrations.SMS,
  },
  HUBSPOT: {
    type: 'CRM',
    isPremium: true,
    label: 'HubSpot',
    value: integrations.HUBSPOT,
  },
  MAILCHIMP: {
    type: 'CRM',
    isPremium: false,
    label: 'Mailchimp',
    value: integrations.MAILCHIMP,
  },
  MARKETO: {
    type: 'CRM',
    isPremium: true,
    label: 'Marketo',
    value: integrations.MARKETO,
  },
  SALESFORCE: {
    type: 'CRM',
    isPremium: true,
    label: 'Salesforce',
    value: integrations.SALESFORCE,
  },
  ZAPIER: {
    type: 'CRM',
    isPremium: false,
    label: 'Zapier',
    value: integrations.ZAPIER,
  },
  FACEBOOKPAGE: {
    type: 'Live Chat',
    isPremium: true,
    label: 'Facebook Page',
    value: integrations.FACEBOOKPAGE,
  },
});