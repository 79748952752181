import React from 'react';

import { getNext, getPrev, steps } from './onboardingSteps';
import BoldSpan from '../components/common/BoldSpan';
import theme from '../theme';

export const defaultPlatform = '';

export const onboardingCopy = {
  getStarted: {
    title: (<>Launch {theme.article} chat in <BoldSpan>THREE</BoldSpan> simple steps.</>),
  }
};

// The last step where nav is inactive
export const navActiveStep = steps.StartTour;

export function getStartedStep(setShow, step) {
  if (setShow === 0) {
    // New component load, determine which step to load
    if (step <= steps.FirstStep) {
      return 1;
    } else if (step === steps.SelectTest) {
      return 3;
    } else if (step === steps.VerifyWeb) {
      return 5;
    }
  } else if (setShow === 3) {
    return 3;
  }
  return setShow;
}

// Set to null to use defaults
export const finalStepID = null;
export const hideStart = null;
export const startMap = {};
export const startButton = null;
export const startTrial = null;
export const theseSteps = {};
export const isTour = null;

export function getNextStep(step) {
  return getNext(step, steps);
};
export function getPrevStep(step) {
  return getPrev(step, steps);
};

export function nextShowStep(setShow, step) {
  return setShow;
}

export function prevShowStep(setShow, step) {
  return setShow;
}