import React from 'react'
import styled from 'styled-components/macro';

const LoadingScreen = () => {
  return (
    <Wrapper>
      <p>Loading...</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 3rem;

  @media (max-width: 750px) {
    padding: 1rem;
  }
`;

export default LoadingScreen
