import {
  THEME,
} from './theme';

const GLOBAL = {
};

function getEnvVars() {
  return Object.assign(GLOBAL, THEME);
}

export default getEnvVars();
