const baseUrl = 'https://sso-api-prod.apigateway.co/_gateway/oauth2/auth';
const dashboardDomain = 'dashboard-login';
const eyelevelUrl = 'https://api.eyelevel.ai/oauth2/vwp';

const defaultClientId = '32ce56e1-f50c-4478-8355-18790d4e9c0d';
const vendastaClientIds = {
  'MP-T2RPGQHKWCMTHBMVCF3NHV6WSPNGPJPW': '32ce56e1-f50c-4478-8355-18790d4e9c0d',
  'MP-J7TCQWF2TRVTVMTP5J84QXW52DMZNQHZ': 'cba19b25-cea1-413d-938f-44057ffe7dac',
  'MP-D7N6G3P3W77GW86BKN733Z7FBNVQQWVX': 'dd03398d-80e8-4a60-bb60-1a6dc4f5a17c',
};

export const defaultPlanId = 'MP-T2RPGQHKWCMTHBMVCF3NHV6WSPNGPJPW';

const VendastaOauthState = (accountId, planId) => {
  return encodeURIComponent(`{"id":"${accountId}","origin":"${window.location.href}","domain":"${dashboardDomain}","planID":"${planId}"}`);
};

export const VendastaOauthURL = (accountId, planId) => {
  let clientId = defaultClientId;
  if (planId && vendastaClientIds[planId]) {
    clientId = vendastaClientIds[planId];
  }
  const state = VendastaOauthState(accountId, planId);
  return `${baseUrl}?client_id=${clientId}&prompt=login&scope=openid+profile&response_type=code&redirect_uri=${eyelevelUrl}&account_id=${accountId}&state=${state}`;
};