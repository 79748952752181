import React from "react";
import styled from "styled-components";

export default function BoldSpan(props) {
  return (
    <Bolded
      {...props}
    >
      {props.children}
    </Bolded>
  );
}

const Bolded = styled.span`
  font-weight: bold;
  color: ${({Colored}) => Colored && 'var(--main-blue)'};
  font-weight: bold;
  font-size: ${({FontSize}) => FontSize && FontSize};
  margin-left: ${({spaceLeft}) => spaceLeft && spaceLeft};
  margin-right: ${({spaceRight}) => spaceRight && spaceRight};
  padding: ${({Padding}) => Padding && Padding};
  touch-action: ${({Clickable}) => Clickable && 'auto'};
  pointer-events: ${({Clickable}) => Clickable && 'all'};

  &:hover {
    cursor: ${({Clickable}) => Clickable && 'pointer'};
  }
`;
