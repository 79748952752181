import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './context/AuthContext';
import AccountProvider from './context/AccountContext';
import CreateGraphProvider from './context/CreateGraphContext';
import OnboardingProvider from './context/OnboardingContext';
import SessionProvider from './context/SessionContext';
import SubscriptionProvider from './context/SubscriptionContext';

import env from './constants/env';

import 'react-toastify/dist/ReactToastify.min.css';
import '@glidejs/glide/dist/css/glide.core.min.css';

import Nav from './components/Navbar/Nav';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

const Account = lazy(() => import('./components/Account/Account'));
const Analytics = lazy(() => import('./components/Analytics/Analytics'));
const ChatPreview = lazy(() => import('./components/ChatPreview/ChatPreview'));
const ConnectFacebook = lazy(() => import('./components/AdAccount/ConnectFacebook'));
const ConnectFacebookPage = lazy(() => import('./components/AdAccount/ConnectFacebookPage'));
const Inbox = lazy(() => import('./components/Inbox/Inbox'));
const ContentComparison = lazy(() => import('./components/AI/ContentComparison/ContentComparison'));
const AIStartPage = lazy(() => import('./components/AI/Onboarding/AIStartPage/AIStartPage'));
const AICreationPage = lazy(() =>
  import('./components/AI/Onboarding/AICreationPage/AICreationPage'),
);
const AIPaymentPage = lazy(() => import('./components/AI/Onboarding/AIPaymentPage/AIPaymentPage'));
const AISignIn = lazy(() => import('./components/AI/Onboarding/AISignIn/AISignIn'));
const AISignUp = lazy(() => import('./components/AI/Onboarding/AISignUp/AISignUp'));
const ManageContentGroup = lazy(() =>
  import('./components/AI/ManageContentGroup/ManageContentGroup'),
);
const CreateContent = lazy(() => import('./components/AI/Create/Create'));
const ManageContent = lazy(() => import('./components/AI/ManageContent/ManageContent'));
const PrepGraph = lazy(() => import('./components/Graph/PrepGraph'));
const Help = lazy(() => import('./components/Help/Help'));
const IntegrationConnect = lazy(() => import('./components/Onboarding/IntegrationConnect'));
const IntroQuestions = lazy(() => import('./components/Graph/IntroQuestions'));
const NotFound = lazy(() => import('./components/NotFound'));
const Reset = lazy(() => import('./components/Authentication/Reset'));
const ResetTestAccount = lazy(() => import('./components/Account/ResetTestAccount'));
const SignIn = lazy(() => import('./components/Authentication/SignIn'));
const SignUp = lazy(() => import('./components/Authentication/SignUp'));
const Splash = lazy(() => import('./components/Dashboard/Splash'));
const StartSubscription = lazy(() => import('./components/Onboarding/StartSubscription'));
const ThankYouPage = lazy(() => import('./components/Subscription/ThankYouPage'));
const DashboardTranslation = lazy(() =>
  import('./components/DashboardTranslation/DashboardTranslation'),
);
const VendastaThankYouPage = lazy(() => import('./components/Subscription/VendastaThankYouPage'));
const ViewGraph = lazy(() => import('./components/Graph/ViewGraph'));
const WordPressDownloadPage = lazy(() =>
  import('./components/WordPress/download/WordPressDownloadPage'),
);

function App() {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (window.Stripe) {
      if (!stripe) {
        setStripe(window.Stripe(env.stripeKey));
      }
    } else {
      window.onload = function () {
        if (!stripe) {
          setStripe(window.Stripe(env.stripeKey));
        }
      };
    }
  }, [stripe]);

  return (
    <Router>
      <SessionProvider>
        <OnboardingProvider stripe={stripe}>
          <AuthProvider>
            <SubscriptionProvider>
              <Nav />
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route path="/preview/:flowName">
                    <ChatPreview />
                  </Route>
                  <Route path="/preview">
                    <ChatPreview />
                  </Route>
                  <Route path="/translation/content/:modelId">
                    <ManageContent />
                  </Route>
                  <Route path="/translation">
                    <DashboardTranslation />
                  </Route>
                  <Route path="/train/:purchaseId">
                    <StartSubscription />
                  </Route>
                  <Route path="/train">
                    <StartSubscription />
                  </Route>
                  <Route path="/onboarding">
                    <CreateGraphProvider>
                      <ViewGraph initOnboarding />
                    </CreateGraphProvider>
                  </Route>
                  <Route path="/view/:UUID">
                    <CreateGraphProvider>
                      <ViewGraph />
                    </CreateGraphProvider>
                  </Route>
                  <Route exact path="/auth/demo/signup">
                    <AISignUp />
                  </Route>
                  <Route path="/auth/admin/signup">
                    <SignUp admin />
                  </Route>
                  <Route path="/auth/signup/:plan">
                    <SignUp />
                  </Route>
                  <Route path="/auth/signup">
                    <SignUp />
                  </Route>
                  <Route exact path="/auth/demo/signin">
                    <AISignIn />
                  </Route>
                  <Route exact path="/auth/signin/:plan">
                    <SignIn />
                  </Route>
                  <Route path="/auth/signin">
                    <SignIn />
                  </Route>
                  <Route path="/auth/signout">
                    <SignIn signout />
                  </Route>
                  <Route path="/auth/reset">
                    <Reset />
                  </Route>
                  <Route path="/analytics/:FlowName">
                    <Analytics />
                  </Route>
                  <Route path="/flow">
                    <CreateGraphProvider>
                      <PrepGraph />
                      <IntroQuestions />
                    </CreateGraphProvider>
                  </Route>{' '}
                  <Route path="/group/:groupId">
                    <ManageContentGroup />
                  </Route>
                  <Route path="/group">
                    <CreateContent isGroup />
                  </Route>
                  <Route path="/content/:modelId/:documentID">
                    <ManageContent />
                  </Route>
                  <Route path="/content/:modelId">
                    <ManageContent />
                  </Route>
                  <Route path="/content">
                    <CreateContent />
                  </Route>
                  <Route path="/project">
                    <CreateGraphProvider>
                      <PrepGraph />
                      <IntroQuestions />
                    </CreateGraphProvider>
                  </Route>
                  <Route path="/template">
                    <CreateGraphProvider>
                      <PrepGraph />
                      <IntroQuestions isTemplateMode={true} />
                    </CreateGraphProvider>
                  </Route>
                  <Route path="/installed/:integration/:integrationError">
                    <Splash />
                  </Route>
                  <Route path="/installed/:integration">
                    <Splash />
                  </Route>
                  <Route path="/transfer/:transferUUID">
                    <Splash />
                  </Route>
                  <Route path="/connect">
                    <IntegrationConnect />
                  </Route>
                  <Route path="/account">
                    {/*<ReauthFB />*/}
                    <AccountProvider>
                      <CreateGraphProvider>
                        <Account />
                      </CreateGraphProvider>
                    </AccountProvider>
                  </Route>
                  <Route exact path="/inbox">
                    <Inbox />
                  </Route>
                  <Route exact path="/ai/content-comparison/:demoUUID">
                    <ContentComparison />
                  </Route>
                  <Route exact path="/inbox/:username">
                    <Inbox />
                  </Route>
                  <Route path="/reset/:username">
                    <ResetTestAccount />
                  </Route>
                  <Route path="/reset">
                    <ResetTestAccount />
                  </Route>
                  <Route exact path="/schedule">
                    <Splash />
                  </Route>
                  <Route exact path="/help">
                    <Help />
                  </Route>
                  <Route exact path="/demo">
                    <AIStartPage />
                  </Route>
                  <Route exact path="/demo/cap/:cap">
                    <AIStartPage />
                  </Route>
                  <Route exact path="/subscription/thank-you">
                    <ThankYouPage />
                  </Route>
                  <Route exact path="/vh/thank-you">
                    <VendastaThankYouPage />
                  </Route>
                  <Route exact path="/demo/:demoUUID/cap/:cap">
                    <AICreationPage />
                  </Route>
                  <Route exact path="/demo/payment">
                    <AIPaymentPage />
                  </Route>
                  <Route exact path="/demo/:demoUUID">
                    <AICreationPage />
                  </Route>
                  <Route path="/facebook" type="authenticate">
                    <ConnectFacebook />
                  </Route>
                  <Route path="/facebook-page">
                    <ConnectFacebookPage />
                  </Route>
                  <Route exact path="/wordpress/:purchaseId">
                    <WordPressDownloadPage />
                  </Route>
                  <Route exact path="/">
                    <CreateGraphProvider>
                      <Splash />
                    </CreateGraphProvider>
                  </Route>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
              <ToastContainer />
            </SubscriptionProvider>
          </AuthProvider>
        </OnboardingProvider>
      </SessionProvider>
    </Router>
  );
}

export default App;
