import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  QUERY_SUBSCRIPTIONS,
  QUERY_SUBSCRIPTION_STATUS,
  UPDATE_SUBSCRIPTION_STATUS,
} from '../constants/schema';

import { useAuth } from './AuthContext';
import { SessionContext } from './SessionContext';

import { getPersonalInformation } from '../utils';

import { QUERY_PARAMS } from '../constants/query';

import {
  isWebCrawl,
  isWordpress,
  initialSetupComplete,
  processSubscription,
  processSubscriptions,
} from '../utils/subscriptions';

import theme from '../theme';

export const SubscriptionContext = createContext();

const setupSteps = {
  Loading: 0,
  TrainingWelcome: 1,
  ChooseTemplate: 2,
  SMSSetupCountry: 3,
  SMSSetupNumber: 4,
  SMSSetupUnsupported: 5,
  EnterURL: 6,
  TrainingStarted: 7,
  CreateTemplateFlow: 8,
  CustomizeChat: 9,
  SuccessSetup: 10,
};

const SubscriptionProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const { isAuthed, isLoading } = useAuth();
  const { processQueryParams, shouldSignIn, vAccountId, vPlanId } = useContext(SessionContext);

  const [accountData, setAccountData] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [country, setCountry] = useState(null);
  const [currentPath, setCurrentPath] = useState(location?.pathname);
  const [currentSearch, setCurrentSearch] = useState(location?.search);
  const [currentStep, setCurrentStep] = useState(setupSteps.Loading);
  const [initSubscriptions, setInitSubscriptions] = useState(false);
  const [loadChoose, setLoadChoose] = useState(false);
  const [loadSetup, setLoadSetup] = useState(false);
  const [loadTraining, setLoadTraining] = useState(false);
  const [lookupTraining, setLookupTraining] = useState(false);
  const [phones, setPhones] = useState([]);
  const [template, setTemplate] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [purchaseId, setPurchaseId] = useState(null);

  const [getSub, { loading: subscriptionLoading, error: subError, data: subData }] = useLazyQuery(
    QUERY_SUBSCRIPTION_STATUS,
    { fetchPolicy: 'network-only' },
  );
  const [getSubs, { loading: subscriptionsLoading, error: subsError, data: subsData }] =
    useLazyQuery(QUERY_SUBSCRIPTIONS, { fetchPolicy: 'network-only' });

  const [createFlows, { loading: creatingFlows }] = useMutation(UPDATE_SUBSCRIPTION_STATUS, {
    async onCompleted() {
      reloadSubscriptions();
      setCurrentStep(setupSteps.CustomizeChat);
    },
    async onError(err) {
      console.log('slack error', err);
      setSubscriptionError(processError('createFlows', err));
    },
  });

  const [updatePhone, { loading: updatingPhone }] = useMutation(UPDATE_SUBSCRIPTION_STATUS, {
    async onCompleted() {
      setCurrentStep(setupSteps.EnterURL);
    },
    async onError(err) {
      console.log('slack error', err);
      setSubscriptionError(processError('updatePhone', err));
    },
  });

  const [updateTemplate, { loading: updatingTemplate }] = useMutation(UPDATE_SUBSCRIPTION_STATUS, {
    async onCompleted() {
      setCurrentStep(setupSteps.EnterURL);
    },
    async onError(err) {
      console.log('slack error', err);
      setSubscriptionError(processError('updateTemplate', err));
    },
  });

  const [updateUrl, { loading: updatingUrl }] = useMutation(UPDATE_SUBSCRIPTION_STATUS, {
    async onCompleted() {
      setCurrentStep(setupSteps.TrainingStarted);
      setLookupTraining(true);
    },
    async onError(err) {
      console.log('slack error', err);
      setSubscriptionError(processError('updateUrl', err));
    },
  });

  const completeTraining = () => {
    setInitSubscriptions(false);
    setCurrentStep(setupSteps.CreateTemplateFlow);
    setLoadChoose(false);
    setLoadSetup(true);
    setLoadTraining(false);
    setSubscriptionError(null);
    createFlows({
      variables: {
        CreateFlows: true,
        PartnerUsername: theme?.partner_username && theme.partner_username,
        PurchaseID: purchaseId,
      },
    });
  };

  const doTemplate = name => {
    setCountry(null);
    setCurrentStep(setupSteps.ChooseTemplate);
    setPhones([]);
    setSubscriptionError(null);
    updateTemplate({
      variables: {
        PartnerUsername: theme?.partner_username && theme.partner_username,
        PurchaseID: purchaseId,
        TemplateType: name,
      },
    });
  };

  const getSubscription = () => {
    if (purchaseId) {
      setSubscriptionError(null);
      getSub({
        variables: {
          PurchaseID: purchaseId,
        },
      });
    } else {
      setSubscriptionError({ request: 'getSubscription', message: 'missing purchaseId' });
    }
  };

  const processError = (ty, err) => {
    let errMsg = {
      request: ty,
      type: 'internal',
      message: err,
    };
    if (err?.message) {
      if (err.message.indexOf('no rows in result set') > -1) {
        errMsg.type = 'not found';
        errMsg.message = 'A subscription with this ID could not be found.';
      } else if (err.message.indexOf('unauthorized to access') > -1) {
        errMsg.type = 'not authorized';
        errMsg.message = 'You are not authorized to access the subscription with this ID.';
      } else {
        errMsg.message = err.message;
      }
    }

    return errMsg;
  };

  const reloadSubscriptions = () => {
    setSubscriptionError(null);
    setInitSubscriptions(true);
    getSubs();
  };

  const selectedCountry = () => {
    return country;
  };

  const selectedTemplate = () => {
    return template;
  };

  const setSelectedCountry = name => {
    if (name && currentStep < setupSteps.EnterURL) {
      if (name === 'us') {
        setCurrentStep(setupSteps.SMSSetupNumber);
      } else if (name === 'international') {
        setCurrentStep(setupSteps.SMSSetupUnsupported);
      }
    }
    setCountry(name);
  };

  const setSelectedTemplate = name => {
    if (name) {
      if (currentStep <= setupSteps.ChooseTemplate) {
        if (name === 'live') {
          setCurrentStep(setupSteps.SMSSetupCountry);
        } else if (name === 'automated') {
          doTemplate(name);
        }
      } else if (name === 'automated') {
        doTemplate(name);
      }
    }
    setTemplate(name);
  };

  const setSubscriptionPhones = ph => {
    if (ph?.length) {
      setSubscriptionError(null);
      updatePhone({
        variables: {
          PartnerUsername: theme?.partner_username && theme.partner_username,
          Phones: ph,
          PurchaseID: purchaseId,
          TemplateType: template,
        },
      });
    }
    setPhones(ph);
  };

  const setSubscriptionUrl = ur => {
    if (ur) {
      setSubscriptionError(null);
      updateUrl({
        variables: {
          PartnerUsername: theme?.partner_username && theme.partner_username,
          PurchaseID: purchaseId,
          URL: ur,
        },
      });
    }
  };

  const toggleSubscriptionStatus = useCallback(sub => {
    if (sub?.WebID && sub?.TemplateID && sub?.ModelID) {
      setLoadChoose(false);
      setLoadSetup(true);
      setLoadTraining(false);
      setCurrentStep(setupSteps.CustomizeChat);
    } else {
      setLoadChoose(false);
      setLoadSetup(false);
      setLoadTraining(true);
      if (!sub?.WebID && !sub?.TemplateID && !sub?.ModelID) {
        setCurrentStep(setupSteps.TrainingWelcome);
      } else if (!sub?.WebID && !sub.ModelID) {
        setCurrentStep(setupSteps.EnterURL);
      } else if (sub.ModelID) {
        setCurrentStep(setupSteps.TrainingStarted);
        setLookupTraining(true);
      }
    }
    if (sub?.TemplateID) {
      if (sub?.LiveChatMetadata) {
        const lcm = JSON.parse(sub.LiveChatMetadata);
        setTemplate('live');
        setCountry('us');
        setPhones(lcm?.phones || []);
      } else {
        setTemplate('automated');
      }
    }
  }, []);

  const startSetup = useCallback(
    sub => {
      if (sub?.Subscription?.PurchaseID) {
        toggleSubscriptionStatus(sub);
        if (isWebCrawl(sub)) {
          history.push(`/train/${sub.Subscription.PurchaseID}`);
        } else if (isWordpress(sub)) {
          history.push(`/wordpress/${sub.Subscription.PurchaseID}`);
        }
      }
    },
    [history, toggleSubscriptionStatus],
  );

  useEffect(() => {
    if (isAuthed && vAccountId && vPlanId) {
      const personalInfo = getPersonalInformation();
      if (!personalInfo?.partnerId || personalInfo.partnerId !== vAccountId) {
        if (currentPath?.indexOf('/auth/') < 0) {
          localStorage.setItem('location', currentPath);
        } else {
          localStorage.setItem('location', '/');
        }
        history.push(
          `/auth/signin/?${QUERY_PARAMS.REAUTH}=true&${QUERY_PARAMS.VENDASTA_ACCOUNT_ID}=${vAccountId}&${QUERY_PARAMS.VENDASTA_PLAN_ID}=${vPlanId}`,
        );
      }
    }
  }, [currentPath, history, isAuthed, vAccountId, vPlanId]);

  useEffect(() => {
    if (location?.pathname) {
      setCurrentPath(location.pathname);
    }

    if (location?.search) {
      setCurrentSearch(location.search);
      processQueryParams(location.search);
    } else {
      const search = localStorage.getItem('search');
      if (search) {
        setCurrentSearch(search);
        processQueryParams(search);
      }
    }
  }, [location, processQueryParams]);

  useEffect(() => {
    // if user is claiming a flow from a "transfer" and not authed
    // set their localStorage
    if (isAuthed === false && shouldSignIn(currentPath)) {
      localStorage.setItem('location', currentPath);
      localStorage.setItem('search', currentSearch);

      if (currentPath.indexOf('/account') === 0) {
        localStorage.setItem('accountSearch', currentSearch);
      }
      history.push('/auth/signin');
    } else if (isAuthed && !initSubscriptions) {
      setInitSubscriptions(true);
      getSubs();
    }
  }, [currentPath, currentSearch, getSubs, history, initSubscriptions, isAuthed, shouldSignIn]);

  useEffect(() => {
    if (initSubscriptions) {
      if (subData?.QuerySubscriptionStatus) {
        toggleSubscriptionStatus(subData.QuerySubscriptionStatus);
        setSubscriptionData(processSubscription(subData.QuerySubscriptionStatus));
      } else {
        setSubscriptionData(subData?.QuerySubscriptionStatus);
      }
    }
  }, [initSubscriptions, subData, toggleSubscriptionStatus]);

  useEffect(() => {
    if (initSubscriptions) {
      if (subsData?.QuerySubscriptions?.AccountInfo?.AccountType) {
        setAccountType(subsData.QuerySubscriptions.AccountInfo.AccountType);
      } else {
        setAccountType('default');
      }
      if (currentPath !== '/reset' && subsData?.QuerySubscriptions) {
        const subs = processSubscriptions(subsData.QuerySubscriptions?.Subscriptions);
        setAccountData(subsData.QuerySubscriptions?.AccountInfo);
        setSubscriptionsData(subs);
        if (subs?.length === 1 && !initialSetupComplete(subs)) {
          startSetup(subs[0]);
        }
      } else {
        setAccountData(subsData?.QuerySubscriptions?.AccountInfo);
        setSubscriptionsData(subsData?.QuerySubscriptions?.Subscriptions);
      }
    }
  }, [currentPath, initSubscriptions, startSetup, subsData]);

  useEffect(() => {
    let errMsg;
    if (subError) {
      errMsg = processError('getSubscription', subError);
      setLoadChoose(true);
      setLoadSetup(false);
      setLoadTraining(false);
      console.log('slack error');
    }
    setSubscriptionError(errMsg);
  }, [subError]);

  useEffect(() => {
    let errMsg;
    if (subsError) {
      errMsg = processError('getSubscriptions', subsError);
      console.log('slack error', errMsg);
    }
    setSubscriptionError(errMsg);
  }, [subsError]);

  return (
    <SubscriptionContext.Provider
      value={{
        accountData,
        accountType,
        isSubscriptionDependencyLoaded:
          !creatingFlows && !subscriptionsLoading && !subscriptionLoading,
        creatingFlows,
        completeTraining,
        currentStep,
        getSubscription,
        isAuthed,
        isLoading,
        loadChoose,
        loadSetup,
        loadTraining,
        lookupTraining,
        phones,
        reloadSubscriptions,
        selectedCountry,
        selectedTemplate,
        setCurrentStep,
        setLookupTraining,
        setSelectedCountry,
        setSubscriptionError,
        setSelectedTemplate,
        setPurchaseId,
        setSubscriptionPhones,
        setSubscriptionUrl,
        setupSteps,
        startSetup,
        subscriptionData,
        subscriptionError,
        purchaseId,
        subscriptionLoading,
        subscriptionsLoading,
        subscriptionsData,
        updatingPhone,
        updatingTemplate,
        updatingUrl,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
