// prettier-ignore
export const steps = {
  VerifyEmail:           0,
  FirstStep:             1,
  AIDemoPlan:            1,
  AIDemoCheckout:        2,
  WordPressPlan:         5,
  WordPressCheckout:     6,
  WordPressDownload:     7,
  ThankYouTrial:         10,
  TrialStarted:          11,
  ConnectChat:           15,
  ConnectWeb:            20,
  VerifyWeb:             21,
  ConnectQR:             30,
  ConnectEmail:          35,
  SelectTest:            40,
  SelectHosting:         41,
  ChooseTemplate:        45,
  TrainAI:               47,
  CreateOnboardingFlow:  50,
  SuccessConnect:        55,
  StartTour:             60,
  ConnectFB:             70,
  ConnectFBAds:          71,
  ConnectFBPage:         72,
  ChoosePlatform:        80,
  OnboardAccount:        98,
  OnboardDashboard:      99,
  ErrorMessage:         100,
  Message:              101,
};

export const startSteps = Object.freeze({
  INIT:        0,
  FIRSTSTEP:   1,
  INTRO:       1,
  SECONDSTEP:  2,
  LIVECHAT:    2,
  THIRDSTEP:   3,
  PLATFORM:    3,
  FOURTHSTEP:  4,
  HOSTING:     4,
  FIFTHSTEP:   5,
  WEBSITE:     5,
  TEMPLATES:   6,
  SIXTHSTEP:   6,
  TRAINAI:     7,
  SEVENTHSTEP: 7,
  LASTSTEP:    7,
});

export const getNext = (step, src) => {
  const items = Object.keys(src).map(function(key) {
    return [key, src[key]];
  });
  items.sort(function(first, second) {
    return first[1] - second[1];
  });

  for (var i = 0; i < items.length; i++) {
    if (items[i][1] === step && i+1 < items.length) {
      return items[i+1][1];
    }
  }

  return 0;
};

export const getPrev = (step, src) => {
  const items = Object.keys(src).map(function(key) {
    return [key, src[key]];
  });
  items.sort(function(first, second) {
    return first[1] - second[1];
  });

  for (var i = 0; i < items.length; i++) {
    if (items[i][1] === step) {
      if (i === 0) {
        return 0;
      }
      return items[i-1][1];
    }
  }

  return 0;
};