import React from "react";
import ReactDOM from "react-dom";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./index.css";
import "./theme/theme.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import env from "./constants/env";
Amplify.configure(env.cognito);

const httpLink = new HttpLink({
  uri: env.graphqlUrl,
});

async function redirectAndClear() {
  // pull off user setting
  const settings = localStorage.getItem("dontShowPageModalForOne");
  const graph = localStorage.getItem("graph");
  localStorage.clear();
  await Auth.signOut();
  // add back user setting
  if (settings === "true") {
    localStorage.setItem("dontShowPageModalForOne", settings);
  }
  if (graph) {
    localStorage.setItem("graph", graph);
  }
  let transferUUID = "";
  const location = window.location.pathname;
  localStorage.setItem("location", location);
  if (window.location.search) {
    localStorage.setItem("search", window.location.search);
    localStorage.setItem('accountSearch', window.location.search);
  }

  if (location.indexOf("/transfer/") > -1) {
    transferUUID = location.replace("/transfer/");
    localStorage.setItem("transferUUID", transferUUID);
  }
  if (window.location.pathname !== "/auth/signin" &&
    window.location.pathname !== "/facebook-page" &&
    (window.location.pathname.indexOf('demo') < 0 && window.location.pathname.indexOf('payment') < 0)) {
    window.location.href = `${window.location.origin}/auth/signin`;
  }
}

const authLink = setContext(async (_, { headers }) => {
  try {
    const res = await Auth.currentSession();
    if (!res) {
      redirectAndClear();
      return;
    }
    return {
      headers: {
        ...headers,
        Authorization:
          res.accessToken && res.accessToken.jwtToken
            ? res.accessToken.jwtToken
            : null,
      },
    };
  } catch (err) {
    redirectAndClear();
    return;
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
