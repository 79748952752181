import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import HamburgerMenu from './subcomponents/HamburgerMenu';
import NavigationLinks from './subcomponents/NavigationLinks';
import VendastaBar from './subcomponents/VendastaBar';

import { convertPxToRem } from '../../utils/convertPxToRem';
import { getPersonalInformation } from '../../utils';
import { OnboardingContext } from '../../context/OnboardingContext';
import { navActiveStep } from '../../constants/onboarding';
import theme from '../../theme';
import { whitelabel } from '../../theme/whitelabel';

const Nav = () => {
  const history = useHistory();

  const userInfo = useMemo(
    () => getPersonalInformation(),
    [],
  );

  const {
    isOnboarding,
    step,
  } = useContext(OnboardingContext);

  const logo = useMemo(
    () => whitelabel('logo', userInfo),
    [userInfo],
  );

  const handleLogoOnClick = () => {
    if (isOnboarding && step <= navActiveStep) {
      return;
    }
    if (theme?.nav?.hideDashboard) {
      return;
    }

    history.push('/');
  };

  return (
    <>
      <VendastaBar />
      <Wrapper>
        <LogoWrapper onClick={handleLogoOnClick}>
          <Logo src={logo} id="navIcon" />
        </LogoWrapper>
        <DesktopNavigation>
          <NavigationLinks />
        </DesktopNavigation>
        <HamburgerMenu />
      </Wrapper>
    </>
  );
};

const DesktopNavigation = styled.nav`
  display: none;
  flex-direction: row;
  color: var(--main-dark-blue);
  font-size: ${convertPxToRem(14)};
  font-weight: bold;

  @media (min-width: 782px) {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: 100%;
`;

const LogoWrapper = styled.button`
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
  z-index: 100;
  top: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  height: ${convertPxToRem(66)};
  padding: ${convertPxToRem(0, 19)};
  background: var(--main-light-blue);
  font-weight: 300;

  @media (min-width: 782px) {
    padding: ${convertPxToRem(0, 48)};
  }
`;

export default Nav;
