import { LOCAL } from '../theme/env';

const ENV = {
  global: {
    facebook: {
      appId: 1366811260153400,
      scope:
        'email,manage_pages,pages_show_list,ads_management,ads_read,pages_messaging,business_management',
    },
    facebookAdsAccountUrl: 'https://business.facebook.com/adsmanager/manage/campaigns?',
  },
  local: {
    aiCardUsers: 10,
    env: 'local',
    graphqlUrl: 'https://devgraph.eyelevel.ai/v1',
    intercom:
      'https://app.intercom.io/oauth?client_id=69a8fd1d-e849-4d3e-a99a-261aef7197bb&redirect_uri=https://devapi.eyelevel.ai/oauth2/intercom',
    slack:
      'https://slack.com/oauth/v2/authorize?client_id=934815298166.919817485378&scope=channels:history,channels:join,channels:manage,channels:read,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,incoming-webhook,mpim:history,mpim:read,mpim:write,team:read,users.profile:read,users:read',
    stripeKey: 'pk_test_lLHT8V3T26yYqoEMvIgsWdlG00WNFPQZy3',
  },
  dev: {
    aiCardUsers: 10,
    env: 'dev',
    graphqlUrl: 'https://devgraph.eyelevel.ai/v1',
    intercom:
      'https://app.intercom.io/oauth?client_id=69a8fd1d-e849-4d3e-a99a-261aef7197bb&redirect_uri=https://devapi.eyelevel.ai/oauth2/intercom',
    slack:
      'https://slack.com/oauth/v2/authorize?client_id=934815298166.919817485378&scope=channels:history,channels:join,channels:manage,channels:read,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,incoming-webhook,mpim:history,mpim:read,mpim:write,team:read,users.profile:read,users:read',
    stripeKey: 'pk_test_lLHT8V3T26yYqoEMvIgsWdlG00WNFPQZy3',
  },
  prod: {
    aiCardUsers: 10,
    env: 'prod',
    graphqlUrl: 'https://graph.eyelevel.ai/v1',
    intercom:
      'https://app.intercom.io/oauth?client_id=69a8fd1d-e849-4d3e-a99a-261aef7197bb&redirect_uri=https://api.eyelevel.ai/oauth2/intercom',
    slack:
      'https://slack.com/oauth/v2/authorize?client_id=226433927106.955782338884&scope=channels:history,channels:join,channels:manage,channels:read,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,incoming-webhook,mpim:history,mpim:read,mpim:write,team:read,users.profile:read,users:read',
    stripeKey: 'pk_live_h7G796nEOgX5bNG5gx0SpWvX00VtVqlR9n',
  },
};

function getEnvVars(env = '') {
  if (env === 'local')
    return Object.assign(
      Object.assign(Object.assign(ENV.local, LOCAL.local), ENV.global),
      LOCAL.global,
    );
  if (env === null || env === undefined || env === '')
    return Object.assign(
      Object.assign(Object.assign(ENV.dev, LOCAL.dev), ENV.global),
      LOCAL.global,
    );
  if (env.indexOf('development') !== -1)
    return Object.assign(
      Object.assign(Object.assign(ENV.dev, LOCAL.dev), ENV.global),
      LOCAL.global,
    );
  if (env.indexOf('production') !== -1)
    return Object.assign(
      Object.assign(Object.assign(ENV.prod, LOCAL.prod), ENV.global),
      LOCAL.global,
    );
  return Object.assign(Object.assign(Object.assign(ENV.dev, LOCAL.dev), ENV.global), LOCAL.global);
}

export default getEnvVars(process.env.REACT_APP_ENV);
