export const LOCAL = {
  global: {
    aid: 12400,
    hostUrl: 'https://chat.eyelevel.ai',
    partnerId: 1,
    splash: 'https://eyelevel.ai/',
  },
  local: {
    baseUrl: 'https://localhost:3000',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '5g4e5qhf0iq4cjbvuving6q9u',
    },
    plans: {
      name: 'Standard Plan',
      message: 'Up to 50 chat engagements a month.',
      month: {
        id: 'price_1HgH25BxgG518sDH8fmahKtF',
        amount: 1799,
        currency: 'usd',
      },
      year: {
        id: 'price_1ISaarBxgG518sDHrubCtoRd',
        amount: 17988,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://localhost:3000/auth',
  },
  dev: {
    baseUrl: 'https://devssp.eyelevel.ai',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '3k9lk0jb3e47vcu5i5doamurgs',
    },
    plans: {
      name: 'Standard Plan',
      message: 'Up to 50 chat engagements a month.',
      month: {
        id: 'price_1HgH25BxgG518sDH8fmahKtF',
        amount: 1799,
        currency: 'usd',
      },
      year: {
        id: 'price_1ISaarBxgG518sDHrubCtoRd',
        amount: 17988,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://devssp.eyelevel.ai/auth',
  },
  prod: {
    baseUrl: 'https://ssp.eyelevel.ai',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '34ljuf7gftrivanrclcfa71n8c',
    },
    plans: {
      name: 'Standard Plan',
      message: 'Up to 50 chat engagements a month.',
      month: {
        id: 'price_1HaPbiBxgG518sDHD75TjFRf',
        amount: 1799,
        currency: 'usd',
      },
      year: {
        id: 'price_1HaTNUBxgG518sDH2E43YPNs',
        amount: 17988,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://ssp.eyelevel.ai/auth',
  },
};
