import React, { useState } from 'react';
import styled from 'styled-components';

import NavigationLinks from './NavigationLinks';
import XMarkIcon from '../../common/IconsComponents/XMarkIcon';

const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => setIsMenuOpen(prev => !prev);

  return (
    <MobileMenu>
      <HamburgerIcon onClick={handleToggleMenu}>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
      </HamburgerIcon>
      <MobileNavigation isActive={isMenuOpen}>
        <CloseButton onClick={handleToggleMenu}>
          <XMarkIcon height={20} width={20} />
        </CloseButton>
        <NavigationLinks handleOnClick={handleToggleMenu} />
      </MobileNavigation>
    </MobileMenu>
  );
};

const CloseButton = styled.div`
  margin-bottom: 47px;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid var(--main-gray);
  border-radius: 5px;
  color: var(--text-gray);
  font-size: 20px;
  background-color: var(--background-light);

  &:hover {
    cursor: pointer;
  }
`;

const HamburgerIcon = styled.div`
  padding: 14px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: var(--background-light);

  &:hover {
    cursor: pointer;
  }
`;

const HamburgerLine = styled.span`
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 3px;
  background-color: var(--main-dark-blue);
`;

const MobileMenu = styled.div`
  display: flex;

  @media (min-width: 782px) {
    display: none;
  } 
`;

const MobileNavigation = styled.nav.attrs(({ isActive }) => ({
  style: { 
    boxShadow: isActive && '0 2px 11px 0 var(--half-shade)',
    transform: `translateX(${isActive ? '-100%' : 0})`,
  },
}))`
  position: fixed;
  top: 0;
  left: 100%;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  background-color: var(--secondary-background-gray);
  transition: 0.3s;
`;

export default HamburgerMenu;