import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
  QUERY_USER_WEB_DATA,
  QUERY_TEMPLATE_CATEGORIES,
  QUERY_TEMPLATES,
  QUERY_PAGES
} from '../constants/schema';
import { prodPlatforms } from '../constants/platformTypes';

import theme from '../theme';

export const CreateGraphContext = createContext();

const CreateGraphProvider = ({ children, isOnboarding }) => {
  const checkLocal = localStorage.getItem('graph');
  const localJson = checkLocal && JSON.parse(checkLocal);
  const isCreate = (localJson?.info && localJson.info === 'create') && localJson?.nodes?.length ? true : false;
  const localGraph = isCreate && localJson;

  const [collectData, setCollectData] = useState({});
  const [selectedWebUrl, setSelectedWebUrl] = useState('');
  const [showFbModal, setShowFbModal] = useState(false);
  const [showFbPageModal, setShowFbPageModal] = useState(false);
  const [runRefetchConnectedFbPages, setRunRefetchConnectedFbPages] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [showWebModal, setShowWebModal] = useState(false);
  const [showQRCodeStyleSelectModal, setShowQRCodeStyleSelectModal] = useState(false);
  const [showQRCodeStyleModal, setShowQRCodeStyleModal] = useState(false);
  const [qrCodeThemeId, setQrCodeThemeId] = useState()
  const [fbAdActId, setFbAdActId] = useState('');
  const [fbPageId, setFbPageId] = useState([]);
  const [projectInit, setProjectInit] = useState(false);
  const [showPlatformModal, setShowPlatformModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(true);
  const [shouldPromptForReuse, setShouldPromptForReuse] = useState(isCreate);
  const [dupeGroupId, setDupeGroupId] = useState(null);
  const [dupeModal, setDupeModal] = useState(false);
  const [dupeProject, setDupeProject] = useState(false);
  const [isActiveAddNewWebsiteModal, setIsActiveAddNewWebsiteModal] = useState(false);
  const [hasDataChosen, setHasDataChosen] = useState(false);
  const [successfullPayment, setSuccessfullPayment] = useState(theme?.skipPayments ? theme.skipPayments : false);

  const { loading: catLoading, data: catData } = useQuery(QUERY_TEMPLATE_CATEGORIES, {
    variables: {
      TemplateUsername: theme?.templates?.default,
      Partnername:      theme.partner_username,
    }
  });
  //const [
  //  grabSubCategories,
  //  { loading: subLoading, error: subError, data: subData },
  //] = useLazyQuery(QUERY_TEMPLATE_CATEGORIES);

  const [grabTemplates, { error: templateErr, data: templateData }] = useLazyQuery(QUERY_TEMPLATES);

  const [
    grabFacebookPages,
    { loading: facebookPagesLoading, error: facebookPagesError, data: facebookPagesData },
  ] = useLazyQuery(QUERY_PAGES, { fetchPolicy: 'network-only' });

  const [
    grabConnectedWebUrls,
    { loading: webLoading, error: webErr, data: webData },
  ] = useLazyQuery(QUERY_USER_WEB_DATA);

  const obtainFBAdAct = id => {
    setFbAdActId(id[0]);
    setShowFbModal(false);
    setHasDataChosen(true);
  };

  const obtainFBPageId = id => {
    setFbPageId(id);
    setShowFbPageModal(false);
    setHasDataChosen(true);
  }

  const handlePlatform = str => {
    setSelectedPlatform(str);
    switch (str) {
      case prodPlatforms.FACEBOOK:
        setShowPlatformModal(false);
        setShowFbModal(true);
        break;
      case prodPlatforms.FACEBOOKPAGE:
        setShowPlatformModal(false);
        grabFacebookPages();
        setShowFbPageModal(true);
        break;
      case prodPlatforms.WEBSITE:
      case prodPlatforms.QRCODE:
      case prodPlatforms.EMAIL:
        setShowPlatformModal(false);
        grabConnectedWebUrls();
        setShowWebModal(true);
        break;
      default:
        break;
    }
  };

  const handleWebUrl = url => {
    setSelectedWebUrl(webData.QueryUserWebData[url].WebID);
    setShowWebModal(false);
    if (dupeGroupId) {
      setDupeModal(true);
    }
  };

  const handleChangeCollectData = fetchedData => setCollectData(fetchedData);
  const useFlow = () => {
    handleChangeCollectData(JSON.parse(localStorage.getItem('graph')));
    setHasDataChosen(true);
  };

  const startOver = () => {
    localStorage.removeItem('graph');
    setShowPlatformModal(true);
  };

  const clearData = () => {
    setShowQRCodeStyleSelectModal(false)
    setSelectedPlatform('');
    setHasDataChosen(false);
    setCollectData({});
  };

  const doNewProject = useCallback((isProject, gid) => {
    setDupeGroupId(gid || null);
    setDupeProject(isProject || false);
    setHasDataChosen(false);
    setSelectedPlatform(prodPlatforms.WEBSITE);
    setShowPlatformModal(false);
    if (!shouldPromptForReuse) {
      grabConnectedWebUrls();
      setShowWebModal(true);
    }
    setProjectInit(true);
  }, [grabConnectedWebUrls, shouldPromptForReuse]);

  const handleTemplateCat = cat => {
    setDupeModal(true);
    if (cat.Type === 'flow') {
      // query templates
      grabTemplates({
        variables: {
          TemplateID: cat.ID,
        },
      });
    } else {
      // TODO: USER HAS CLICKED A CATEGORY THAT HAS SUBCATEGORIES
      // FETCH AND PRESENT THESE TO USER
      // NO DESIGN FOR THIS YET
      // category is of type category... fetch subcategories
      //setShowSubCat(true);
      //grabSubCategories({
      //  variables: {
      //    Category: cat.ID,
      //  },
      //});
    }
  };

  useEffect(() => {
    if (showProjectModal && !projectInit) {
      doNewProject();
    }
  }, [doNewProject, projectInit, showProjectModal]);

  return (
    <CreateGraphContext.Provider
      value={{
        catData,
        catLoading,
        clearData,
        collectData,
        doNewProject,
        dupeGroupId,
        dupeModal,
        dupeProject,
        fbAdActId,
        fbPageId,
        facebookPagesData,
        facebookPagesError,
        facebookPagesLoading,
        grabFacebookPages,
        handleChangeCollectData,
        handlePlatform,
        handleTemplateCat,
        handleWebUrl,
        hasDataChosen,
        isActiveAddNewWebsiteModal,
        isOnboarding,
        localGraph,
        obtainFBAdAct,
        obtainFBPageId,
        runRefetchConnectedFbPages,
        setRunRefetchConnectedFbPages,
        selectedPlatform,
        selectedWebUrl,
        setIsActiveAddNewWebsiteModal,
        setDupeGroupId,
        setDupeModal,
        setDupeProject,
        setHasDataChosen,
        setSelectedPlatform,
        setShouldPromptForReuse,
        setShowFbModal,
        setShowFbPageModal,
        setShowPlatformModal,
        setShowProjectModal,
        setShowWebModal,
        setShowQRCodeStyleSelectModal,
        setShowQRCodeStyleModal,
        setQrCodeThemeId,
        setSuccessfullPayment,
        successfullPayment,
        shouldPromptForReuse,
        showFbModal,
        showFbPageModal,
        showPlatformModal,
        showProjectModal,
        showWebModal,
        showQRCodeStyleSelectModal,
        showQRCodeStyleModal,
        startOver,
        templateData,
        templateErr,
        useFlow,
        qrCodeThemeId,
        webData,
        webErr,
        webLoading,
      }}
    >
      {children}
    </CreateGraphContext.Provider>
  );
};

export default CreateGraphProvider;
