import React from 'react';

import { convertPxToRem } from '../utils/convertPxToRem';
import theme from '../theme';

export const graphTour = [
  {
    target: '#root',
    content: `The ${theme.name} interface is divided into two sections.`,
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'center',
    spotlightPadding: '0',
  },
  {
    target: '#graphWrap',
    content:
      'This section contains a visual layout of your conversation flow. The lines and colored boxes represent the progression of the automated chat flow that your users will experience.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
    styles: {
      overlay: {
        mixBlendMode: 'darken',
      },
      spotlight: {
        backgroundColor: '#ffffff',
      },
    },
  },
  {
    target: '#rootNode',
    content: 'This is a step, an element of your chat flow. Clicking on a step...',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightPadding: '2',
  },
  {
    target: '#editWrap',
    action: 'click',
    node: 'root',
    content: '...will load the step into the flow editor.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
  },
  {
    target: '#editControls',
    content: 'These buttons will allow you to edit or delete an existing step.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
    styles: {
      spotlight: {
        marginLeft: convertPxToRem('-8'),
        marginTop: convertPxToRem('-4'),
        padding: `0 ${convertPxToRem('8')}`,
      },
    },
  },
  {
    target: '#addButton',
    content:
      'This button will allow you to add the next step following the currently selected step.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
    styles: {
      spotlight: {
        marginLeft: convertPxToRem('-8'),
        marginTop: convertPxToRem('-8'),
        padding: convertPxToRem('8'),
      },
    },
  },
  {
    target: '#graphWrap',
    content:
      'Clicking on the white space in the flow visualizer will deselect the currently selected step.',
    action: 'click',
    node: 'open',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
    styles: {
      overlay: {
        mixBlendMode: 'darken',
      },
      spotlight: {
        backgroundColor: '#ffffff',
      },
    },
  },
  {
    target: '#rootEdge',
    content: 'To disconnect two existing steps from each other, click on the line connecting them.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
  },
  {
    target: 'body',
    action: 'click',
    node: 'root-edge',
    content: (
      <>
        This will make a <b>REMOVE CONNECTION</b> button visible.
      </>
    ),
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'center',
    spotlightPadding: '0',
  },
  {
    target: '#removeConnection',
    content: 'Click it to disconnect the two steps.',
    action: 'click',
    node: 'root-edge',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
  },
  {
    target: '#connectNodes',
    content: 'To connect two existing steps, click this button.',
    action: 'click',
    node: 'open',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '0',
    styles: {
      spotlight: {
        marginLeft: convertPxToRem('-8'),
        marginTop: convertPxToRem('-8'),
        padding: convertPxToRem('8'),
      },
    },
  },
  {
    target: '#rootNode',
    content: 'Then click the first step you wish to connect...',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightPadding: '2',
  },
  {
    target: '#secondNode',
    content: '...and drag your mouse to the second step.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightPadding: '2',
  },
  {
    target: '#edit-name-step',
    content: 'To update the name of your conversation flow, click on the name here.',
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '2',
  },
  {
    target: '#save-step',
    content: (
      <>
        <b>SAVE FLOW</b> to immediately deploy your changes to your website.
      </>
    ),
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '4',
  },
  {
    target: '#preview-step',
    content: (
      <>
        <b>PREVIEW FLOW</b> to view the changes on your website.
      </>
    ),
    disableBeacon: true,
    hideBackButton: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightPadding: '4',
  },
  {
    target: '#publish-step',
    content: (
      <>
        Publish to deploy your conversation flow to additional marketing channels such as{' '}
        <b>QR CODES</b>, <b>EMAIL</b>, and <b>FACEBOOK</b>.
      </>
    ),
    hideBackButton: true,
    hideCloseButton: true,
    disableBeacon: true,
    placement: 'auto',
    spotlightPadding: '4',
  },
];
