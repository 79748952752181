import styled, { css, keyframes } from 'styled-components';

import { convertPxToRem } from '../../utils/convertPxToRem';

const spinnerRotation = keyframes`
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
`;

export const oneLineEllipsis = css`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const multiLineEllipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberOfLines }) => (numberOfLines ? numberOfLines : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const buttonBaseStyles = css`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: ${({ widthAuto }) => !widthAuto && '100%'};
  max-width: ${({ widthAuto }) => !widthAuto && convertPxToRem(216)};
  padding: ${convertPxToRem(0, 24)};
  border: 0;
  opacity: ${({ isDisabled = false }) => (isDisabled ? '0.5' : '1')};
  background-color: var(--${({ isActive = true }) => (isActive ? 'main-blue' : 'text-gray')});
  color: var(--text-light);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  & > p {
    margin: 0;
    overflow: hidden;
  }
`;

const inputsBaseStyles = css`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  border: ${convertPxToRem(1)} solid
    var(--${({ isError }) => (isError ? 'error-red' : 'main-gray')});
  border-radius: ${convertPxToRem(6)};
  background-color: var(--background-light);
  color: var(--text-gray);
  font-size: ${convertPxToRem(12)};
  line-height: ${convertPxToRem(16)};
  touch-action: auto;
  pointer-events: all;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    border-color: var(--secondary-blue);
    outline: none;
  }
`;

export const Input = styled.input`
  ${inputsBaseStyles}
  height: ${convertPxToRem(38)};
  max-height: ${convertPxToRem(38)};
  padding: ${convertPxToRem(8, 13)};

  &::placeholder {
    color: var(--main-gray);
  }
`;

export const TextArea = styled.input`
  ${inputsBaseStyles}
  height: ${convertPxToRem(38)};
  max-height: ${convertPxToRem(38)};
  padding: ${convertPxToRem(8, 13)};

  &::placeholder {
    color: var(--main-gray);
  }
`;

export const P = styled.p`
  margin: 0;
  color: var(--text-gray);
  font-size: ${convertPxToRem(16)};
  line-height: ${convertPxToRem(22)};
`;

export const PlacementLabel = styled.div`
  color: ${({ isActive }) => (isActive ? 'var(--main-blue)' : 'var(--text-gray)')};
  display: inline;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: ${convertPxToRem(14)};
  cursor: pointer;
  margin: ${convertPxToRem(0, 12, 0, 0)};
`;

export const PlacementLabels = styled.div`
  width: ${({ width }) => (width && width)};
  touch-action: auto;
  pointer-events: all;
`;

export const PrimaryButton = styled.button`
  ${buttonBaseStyles}
  height: ${({ height }) => height ? height : convertPxToRem(40)};
  border-radius: ${convertPxToRem(4)};
  font-size: ${convertPxToRem(14)};

  & > p {
    ${multiLineEllipsis}
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;
  color: var(--main-blue);
`;

export const Select = styled.select`
  ${inputsBaseStyles}
  height: ${convertPxToRem(38)};
  max-height: ${convertPxToRem(38)};
  padding: ${convertPxToRem(8, 13)};
`;

export const SmallPrimaryButton = styled.button`
  ${buttonBaseStyles}
  width: 100%;
  height: ${convertPxToRem(25)};
  border-radius: ${convertPxToRem(3)};
  background-color: var(--main-dark-gray);
  font-size: ${convertPxToRem(10)};

  & > p {
    ${oneLineEllipsis}
  }
`;

export const SmallSecondaryButton = styled(SmallPrimaryButton)`
  background-color: transparent;
  color: var(--main-blue);
`;

export const Spinner = styled.div`
  position: relative;
  width: 1em;
  height: 1em;
  margin: ${convertPxToRem(100, 'auto')};
  transform: translateZ(0);
  animation: ${spinnerRotation} 1.3s infinite linear;
  border-radius: 50%;
  color: var(--text-gray);
  font-size: ${convertPxToRem(20)};
  text-indent: -9999em;
`;

export const Status = styled.span`
  color: var(--${({ isError }) => (isError ? 'error-red' : 'text-gray')});
  font-size: ${convertPxToRem(14)};
`;

export const TableButton = styled.button`
  ${buttonBaseStyles}
  height: ${convertPxToRem(30)};
  padding: 0 ${convertPxToRem(13)};
  border: 2px solid var(--main-blue);
  border-radius: ${convertPxToRem(4)};
  background-color: var(--background-light);
  color: var(--main-dark-blue);
  font-size: ${convertPxToRem(12)};
  font-weight: bold;

  & > span {
    ${oneLineEllipsis}
  }
`;

export const TableElement = styled.li`
  display: grid;
  box-sizing: border-box;
  height: ${convertPxToRem(48)};

  & > * {
    align-self: center;
    margin: 0;
  }
`;

export const TableList = styled.ul`
  display: flex;
  flex-direction: column;
  background-image: repeating-linear-gradient(
    var(--secondary-background-gray),
    var(--secondary-background-gray) ${convertPxToRem(48)},
    var(--background-light) ${convertPxToRem(48)},
    var(--background-light) ${convertPxToRem(96)}
  );
`;

export const Textarea = styled.textarea`
  ${inputsBaseStyles}
  height: ${convertPxToRem(99)};
  padding: ${convertPxToRem(11, 13)};
  resize: none;

  &::-webkit-scrollbar {
    width: ${convertPxToRem(6)};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${convertPxToRem(3)};
    background-color: var(--main-gray);
  }
`;

export const TooltipWrapper = styled.div`
  && {
    display: block;
  }

  & > p {
    display: inline;
  }
`;
