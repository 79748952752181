import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { convertStep, getUser, grabOnboardingInfo, refreshToken, postData } from '../utils';
import {
  defaultPlatform,
  finalStepID,
  getNextStep,
  getPrevStep,
  hideStart,
  isTour,
  nextShowStep,
  onboardingCopy,
  prevShowStep,
  startButton,
  startMap,
  startTrial,
} from '../constants/onboarding';
import { startSteps, steps } from '../constants/onboardingSteps';
import { graphTour } from '../constants/tour';
import { prodPlatforms } from '../constants/platformTypes';
import { SessionContext } from './SessionContext';

import env from '../constants/env';
import theme from '../theme';
import { userOnboardingTypes } from '../theme/theme';

import { QUERY_USER_WEB_DATA, SET_ONBOARDED, UPDATE_ONBOARDING } from '../constants/schema';

export const OnboardingContext = createContext();

const finalStep = finalStepID || steps.ChoosePlatform;

const isGraphTourStep =
  isTour ||
  function (st) {
    if (st === steps.StartTour) {
      return true;
    }
    return false;
  };

const isStartStep = (st) => {
  if (
    st === steps.ConnectChat
    || st === steps.ConnectWeb
    || st === steps.VerifyWeb
    || st === steps.ConnectQR
    || st === steps.ConnectEmail
    || st === steps.SelectTest
    || st === steps.SelectHosting
    || st === steps.ChooseTemplate
    || st === steps.CreateOnboardingFlow
    || st === steps.SuccessConnect
    || st === steps.TrialStarted
    || st === steps.TrainAI
  ) {
    return true;
  }
  return false;
};

const thirdStartButton = startButton || false;

const trialStarted =
  startTrial ||
  function () {
    return true;
  };

const OnboardingProvider = ({ children, stripe }) => {
  const [doFBAds, setDoFBAds] = useState(false);
  const [doFBConnect, setDoFBConnect] = useState(false);
  const [hideStartStep, setHideStartStep] = useState(
    hideStart || {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: true,
    },
  );
  const [emptyModal, setEmptyModal] = useState(false);
  const [facebookData, setFacebookData] = useState({});
  const [facebookModal, setFacebookModal] = useState(false);
  const [flowData, setFlowData] = useState({});
  const [isOnboarding, setIsOnboarding] = useState(true);
  const [onboardingInfo, setOnboardingInfo] = useState(false);
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [platform, setPlatform] = useState(defaultPlatform);
  const [platformID, setPlatformID] = useState('');
  const [publishModal, setPublishModal] = useState(false);
  const [processStep, setProcessStep] = useState(false);
  const [selectedWebUrl, setSelectedWebUrl] = useState('');
  const [step, setStep] = useState(1);
  const [startTour, setStartTour] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [template, setTemplate] = useState('');
  const [tourCompleted, setTourCompleted] = useState(false);
  const [tourStep, setTourStep] = useState(-1);
  const [transfer, setTransfer] = useState(false);
  const [webModal, setWebModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { integration, integrationError } = useContext(SessionContext);

  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);
  const [updateOnboarded] = useMutation(SET_ONBOARDED);
  const [grabConnectedWebUrls, { loading: webLoading, error: webErr, data: webData }] =
    useLazyQuery(QUERY_USER_WEB_DATA);

  useEffect(() => {
    if (webData && !webLoading) {
      openModal('platform');
    }
  }, [webData, webLoading]);

  const closeModal = () => {
    setDoFBAds(false);
    setDoFBConnect(false);
    setEmptyModal(false);
    setFacebookModal(false);
    setOnboardingModal(false);
    setPublishModal(false);
    setWebModal(false);
  };

  const openModal = mtype => {
    // Onboarding modals
    if (
      mtype === 'livechat' ||
      mtype === 'fbconnect' ||
      mtype === 'fbads' ||
      mtype === 'dashboard'
    ) {
      setEmptyModal(false);
      setFacebookModal(false);
      setOnboardingModal(true);
      setPublishModal(false);
      setWebModal(false);
      // FB Page modal
    } else if (mtype === 'pages') {
      setEmptyModal(false);
      setFacebookModal(true);
      setOnboardingModal(false);
      setPublishModal(false);
      setWebModal(false);
      // Web modal
    } else if (mtype === 'web' || mtype === 'choose') {
      setEmptyModal(false);
      setFacebookModal(false);
      setOnboardingModal(false);
      setPublishModal(false);
      setWebModal(true);
      // Omnipublish
    } else if (mtype === 'platform') {
      setEmptyModal(false);
      setFacebookModal(false);
      setOnboardingModal(false);
      setPublishModal(true);
      setWebModal(false);
      // Shaded modal screen
    } else if (mtype === 'empty') {
      setEmptyModal(true);
      setFacebookModal(false);
      setOnboardingModal(true);
      setPublishModal(false);
      setWebModal(false);
    }

    switch (mtype) {
      case 'fbconnect':
        setDoFBAds(false);
        setDoFBConnect(true);
        break;
      case 'fbads':
        setDoFBAds(true);
        setDoFBConnect(false);
        break;
      default:
        setDoFBAds(false);
        setDoFBConnect(false);
        break;
    }
  };

  const getOnboardingInfo = () => {
    const info = grabOnboardingInfo();

    setOnboardingInfo(info);
    // pull off step user should potentially go to
    const stepToSet = convertStep(info.step);
    if (info?.onboardedNormalize === 'true' || info?.onboarded || theme?.nav?.ignoreOnboarding?.includes(info?.['partnerUsername'])) {
      setIsOnboarding(false);
    } else {
      setIsOnboarding(true);
      setStep(stepToSet);
    }

    return info;
  };

  const handleOnboardingClicks = async (cType, shouldSkip) => {
    let info;
    if (!shouldSkip) {
      info = await refreshOnboarding();
    }
    if (!info) {
      info = onboardingInfo;
    }

    if (cType === 'preview') {
      const previewURL =
        (info?.companyURL || env.hostUrl) +
        '?eyreset=true&eystate=open&fn=' +
        flowData.name +
        (env.env === 'local' || env.env === 'dev' ? '&eyenv=dev' : '');
      updateCRM({ step: 98, message: `previewed chat from the Graph at ${previewURL}` });
      window.open(previewURL, '_blank');
    } else if (cType === 'publish') {
      startPublish();
    }
  };

  const handleWebUrl = url => {
    setSelectedWebUrl(webData.QueryUserWebData[url].WebID);
    closeModal();
  };

  const directOnboarding = async () => {
    const onboardingStep = await loadOnboarding();
    const onboardingInfo = grabOnboardingInfo();

    if (!onboardingStep) {
      if (!onboardingInfo?.onboardedNormalize) {
        history.push('/');
      }
      return onboardingStep;
    }

    if (onboardingInfo?.onboardingType) {
      if (!onboardingInfo?.subscriptions) {
        if (onboardingInfo.onboardingType === userOnboardingTypes.AI_ONBOARDING) {
          if (onboardingStep !== steps.AIDemoCheckout) {
            nextStep(steps.AIDemoCheckout, steps.AIDemoPlan, {
              step: steps.AIDemoPlan,
              message: 'logged into their AI demo account on SSP',
            });
          }
          history.push('/demo/payment');
          return steps.AIDemoCheckout;
        } else if (onboardingInfo.onboardingType === userOnboardingTypes.WORDPRESS_ONBOARDING) {
          if (onboardingStep !== steps.WordPressCheckout) {
            nextStep(steps.WordPressCheckout, steps.WordPressPlan, {
              step: steps.WordPressPlan,
              message: 'logged into their WordPress account on SSP',
            });
          }
          history.push('/wordpress/payment');
          return steps.WordPressCheckout;
        }
      } else if (onboardingInfo.onboardingType === userOnboardingTypes.AI_ONBOARDING) {
        if (onboardingStep < steps.ThankYouTrial) {
          nextStep(steps.ThankYouTrial, steps.ThankYouTrial, {
            step: steps.ThankYouTrial,
            message: 'logged into their AI demo account on SSP',
          });
        }
        if (onboardingStep <= steps.ThankYouTrial) {
          history.push('/subscription/thank-you');
          return steps.ThankYouTrial;
        }
      }
    }

    if (onboardingInfo?.demoUUID && !onboardingInfo?.subscriptions) {
      if (onboardingStep !== steps.AIDemoCheckout) {
        nextStep(steps.AIDemoCheckout, steps.AIDemoPlan, {
          step: steps.AIDemoPlan,
          message: 'logged into their AI demo account on SSP',
        });
      }
      history.push('/demo/payment');
      return steps.AIDemoCheckout;
    } else if (onboardingInfo?.pendingSubscription) {
      if (onboardingStep !== steps.WordPressCheckout) {
        nextStep(steps.WordPressCheckout, steps.WordPressPlan, {
          step: steps.WordPressPlan,
          message: 'logged into their WordPress account on SSP',
        });
      }
      history.push('/wordpress/payment');
      return steps.WordPressCheckout;
    } else if (isGraphTourStep(onboardingStep)) {
      history.push('/');
    } else if (isStartStep(onboardingStep)) {
      if (!location?.pathname || !location.pathname?.indexOf || location.pathname.indexOf('train') === -1) {
        history.push('/train');
      }
    } else if (!onboardingInfo?.onboardedNormalize) {
      history.push('/');
    }

    return onboardingStep;
  };

  const isDashboardModal = st => {
    if (st === steps.ChooseTemplate || st === steps.NameTest) {
      return true;
    }
    return false;
  };

  const updateOnboardingFlowUUID = (uuid, cb) => {
    if (processStep) {
      if (cb) {
        return cb();
      }
      return;
    }
    setProcessStep(true);

    const saved = {
      Step: step,
      FlowUUID: uuid,
    };

    updateOnboarding({ variables: saved }).then(async data => {
      await refreshOnboarding();
      setProcessStep(false);
      if (cb) {
        cb(data?.data?.UpdateOnboarding ? data.data.UpdateOnboarding : data);
      }
    });
  };

  const nextStep = async (next, curr, crm, opts, cb) => {
    if (processStep) {
      if (cb) {
        cb();
      }
      return Promise.resolve();
    }
    setProcessStep(true);

    if (!curr) {
      curr = step;
    }
    if (!next) {
      next = getNextStep(curr);
    }

    if (next === curr || next === 0) {
      setProcessStep(false);
      if (cb) {
        cb();
      }
      return Promise.resolve();
    }

    updateStep(next);
    if (curr >= finalStep) {
      setOnboarded();
    }

    trackOnboarding(next, curr);

    updateCRM(crm);

    let saved = {
      Step: next,
    };
    if (opts?.uuid) {
      saved.FlowUUID = opts.uuid;
    }
    if (opts?.platform) {
      saved.Platform = opts.platform;
    }

    return updateOnboarding({ variables: saved }).then(async data => {
      await refreshOnboarding();
      setProcessStep(false);
      if (cb) {
        cb(data?.data?.UpdateOnboarding ? data.data.UpdateOnboarding : data);
      }
      return data;
    });
  };

  const prevStep = async (prev, curr, cb) => {
    if (processStep) {
      if (cb) {
        return cb();
      }
      return;
    }
    setProcessStep(true);

    if (!curr) {
      curr = step;
    }
    if (!prev) {
      prev = getPrevStep(curr);
    }

    if (prev === curr || prev === 0) {
      setProcessStep(false);
      if (cb) {
        return cb();
      }
      return;
    }

    updateStep(prev);

    trackOnboarding(prev, curr);

    const saved = {
      Step: prev,
    };

    return updateOnboarding({ variables: saved }).then(async data => {
      await refreshOnboarding();
      setProcessStep(false);
      if (cb) {
        cb(data && data.data && data.data.UpdateOnboarding ? data.data.UpdateOnboarding : data);
      }
    });
  };

  const refreshOnboarding = async () => {
    const res = await refreshToken();
    if (res === 'done') {
      return getOnboardingInfo();
    }
    return;
  };

  const setOnboarded = cb => {
    updateOnboarded({}).then(async data => {
      updateCRM({ step: 98, message: `completed onboarding by publishing to ${platform}` });
      await refreshOnboarding();
      if (cb) {
        cb(data && data.data && data.data.SetOnboarded ? data.data.SetOnboarded : data);
      }
    });
  };

  const shouldStartTour = async () => {
    const info = await refreshOnboarding();

    // pull off step user should potentially go to
    const stepToSet = convertStep(info.step);
    if (stepToSet === steps.StartTour && !startTour) {
      return true;
    }
    return false;
  };

  const skipTour = () => {
    nextStep(null, steps.StartTour, { step: steps.StartTour, message: 'completed the tour' });
    setTourCompleted(true);
  };

  const trackOnboarding = (next, curr) => {
    window.dataLayer.push({
      event: 'onboarding',
      onboardingPage: '/onboarding-step-' + next,
    });
    if (curr === steps.SuccessConnect) {
      if (window.gtag) {
        window.gtag_report_conversion('AW-765138786/2woACJPit-UBEOKu7OwC');
      }
    } else if (curr === steps.VerifyEmail) {
      if (window.gtag) {
        window.gtag_report_conversion('AW-765138786/EVR_CMWxwuUBEOKu7OwC');
      }
    }
  };

  const tourEvents = ev => {
    if (
      (ev?.action === 'start' || ev?.action === 'update' || ev?.action === 'next') &&
      ev?.lifecycle === 'ready'
    ) {
      setTourStep(ev.index);
    } else if (ev?.status === 'finished' && ev?.lifecycle === 'complete') {
      nextStep(null, steps.StartTour, { step: steps.StartTour, message: 'completed the tour' });
      setTourCompleted(true);
    }
  };

  const startPublish = async (next, pubPlatform) => {
    if (next) {
      if (next === steps.ChoosePlatform) {
        switch (pubPlatform) {
          case prodPlatforms.WEBSITE:
            updateStep(steps.ConnectWeb);
            openModal('choose');
            break;
          case prodPlatforms.QRCODE:
            updateStep(steps.ConnectQR);
            openModal('choose');
            break;
          case prodPlatforms.EMAIL:
            updateStep(steps.ConnectEmail);
            openModal('choose');
            break;
          default:
            break;
        }
      }
    } else if (!webLoading) {
      if (!webData) {
        grabConnectedWebUrls();
      } else {
        openModal('platform');
      }
    }
  };

  const updateCRM = (crm, cb) => {
    const user = getUser();
    let data = {
      username: user?.username || '',
      email: user?.email || '',
      name: user?.name || '',
      step: crm.step,
      partner_username: theme?.partner_username || '',
    };
    for (var i in crm) {
      if (i !== 'step') {
        if (i === 'message') {
          data[i] = `[${theme.name}] ${crm[i]}`;
        } else {
          data[i] = crm[i];
        }
      }
    }

    if (env.env === 'local' || env.env === 'dev') {
      data.dev = true;
    }

    postData('https://api.eyelevel.ai/register/onboarding', data)
      .then(d => {
        if (cb) {
          cb(d);
        }
      })
      .catch(e => {
        console.error('POST to register/onboarding', e);
        // TODO: log error
        if (cb) {
          cb(null, e);
        }
      });
  };

  const updateStep = up => {
    if (onboardingInfo?.onboardedNormalize === 'true') {
    } else {
      setStep(up);
    }
  };

  const loadOnboarding = async () => {
    const info = await refreshOnboarding();
    // pull off step user should potentially go to
    if (info?.onboardedNormalize === 'true') {
      closeModal();
    } else {
      // user is not onboarded
      const stepToSet = convertStep(info?.step || steps.FirstStep);
      if (history.location.pathname === '/') {
        trackOnboarding(info?.step || steps.FirstStep);
      }
      if (isDashboardModal(stepToSet)) {
        openModal('dashboard');
      }
      return stepToSet;
    }
    return;
  };

  return (
    <OnboardingContext.Provider
      value={{
        closeModal,
        directOnboarding,
        doFBAds,
        doFBConnect,
        emptyModal,
        facebookData,
        facebookModal,
        finalStep,
        flowData,
        graphTour,
        handleOnboardingClicks,
        handleWebUrl,
        hideStartStep,
        setHideStartStep,
        integration,
        isGraphTourStep,
        isOnboarding,
        isStartStep,
        loadOnboarding,
        nextShowStep,
        nextStep,
        onboardingCopy,
        onboardingInfo,
        onboardingModal,
        openModal,
        platform,
        platformID,
        integrationError,
        prevShowStep,
        prevStep,
        processStep,
        publishModal,
        selectedWebUrl,
        setFacebookData,
        setFlowData,
        setOnboarded,
        setPlatform,
        setPlatformID,
        setSelectedWebUrl,
        setStartTour,
        setSubscriptionPlan,
        setTemplate,
        setTourCompleted,
        setTransfer,
        shouldStartTour,
        skipTour,
        startMap,
        startPublish,
        startSteps,
        startTour,
        step,
        steps,
        stripe,
        subscriptionPlan,
        template,
        thirdStartButton,
        trackOnboarding,
        transfer,
        trialStarted,
        tourEvents,
        tourStep,
        tourCompleted,
        updateCRM,
        updateOnboardingFlowUUID,
        webData,
        webErr,
        webLoading,
        webModal,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
