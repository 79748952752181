import gql from 'graphql-tag';
import {
  AdAccountData,
  AIResponseData,
  AISearchData,
  ChatMessageData,
  CustomerData,
  FacebookConnectResponseData,
  GraphData,
  InvoicesData,
  MailchimpConnectResponseData,
  ModelData,
  ModelEngine,
  OK,
  PagesData,
  PixelData,
  ProcessingProject,
  SubscriptionData,
  TrainFileData,
  TranslationLanguage,
} from './fragments';

// queries

export const GET_FLOW = gql`
  query GetFlow($UUID: UUID!, $Count: Int) {
    GetFlow(UUID: $UUID, Count: $Count) {
      ...GraphData
    }
  }
  ${GraphData}
`;

export const QUERY_FLOWS = gql`
  query QueryFlows($Name: String, $Type: String, $Platform: String, $PlatformID: String) {
    QueryFlows(Name: $Name, Type: $Type, Platform: $Platform, PlatformID: $PlatformID) {
      uuid
      created
      name
      desc
      type
      platforms
      active
    }
  }
`;

export const QUERY_SUBSCRIPTIONS = gql`
  query QuerySubscriptions($Username: String) {
    QuerySubscriptions(Username: $Username) {
      AccountInfo {
        AccessLevel
        AccountType
        ContentLimit
        GroupLimit
        ProjectLimit
      }
      Subscriptions {
        Flow {
          uuid
          created
          name
          desc
          type
          platforms
          active
        }
        Model {
          ...ModelData
        }
        Subscription {
          ...SubscriptionData
        }
        WebData {
          Name
          URL
          WebID
        }
      }
    }
  }
  ${ModelData}
  ${SubscriptionData}
`;

export const QUERY_SUBSCRIPTION_STATUS = gql`
  query QuerySubscriptionStatus($PurchaseID: String!) {
    QuerySubscriptionStatus(PurchaseID: $PurchaseID) {
      ...SubscriptionData
    }
  }
  ${SubscriptionData}
`;



export const QUERY_TRAINING_STATUS = gql`
  query QueryTrainingStatus($IncludeMetadata: Boolean, $ModelID: Int64, $PurchaseID: String) {
    QueryTrainingStatus(IncludeMetadata: $IncludeMetadata, ModelID: $ModelID, PurchaseID: $PurchaseID) {
      model {
        Files{
          ...TrainFileData
        }
        previewFlowId
        ownerUUID
        urlTraining{
          metadata
	        qualityRating
	        status
	        taskId
        }
      }
    }
  }
  ${TrainFileData}
`;

export const QUERY_TRANSLATION_LANGUAGES = gql`
  query QueryTranslationLanguages($Username: String) {
    QueryTranslationLanguages(Username: $Username) {
      ...TranslationLanguage
    }
  }
  ${TranslationLanguage}
`;

export const QUERY_PROCESSING_PROJECTS = gql`
  query QueryProcessingProjects($Type: String!, $Username: String) {
    QueryProcessingProjects(Type: $Type, Username: $Username) {
      ...ProcessingProject
    }
  }
${ProcessingProject}
`;

export const QUERY_AI_MODEL_ENGINES = gql`
  query QueryAIModelEngines($Username: String, $ModelID: Int64) {
    QueryAIModelEngines(Username: $Username, ModelID: $ModelID) {
      ...ModelEngine
    }
  }
  ${ModelEngine}
`;

export const QUERY_AI_MODELS = gql`
  query QueryAIModels($ActiveModel: Int64, $AddTraining: Boolean, $FlowName: String, $Purpose: String, $Username: String) {
    QueryAIModels(ActiveModel: $ActiveModel, AddTraining: $AddTraining, FlowName: $FlowName, Purpose: $Purpose, Username: $Username) {
      ...ModelData
    }
  }
  ${ModelData}
`;

export const QUERY_MESSAGE_META = gql`
  query QueryMessageMeta($TurnUUID: UUID!) {
    QueryMessageMeta(TurnUUID: $TurnUUID) {
      ErrorMessage
      Models {
        ...ModelData
      }
      Response {
        ...AIResponseData
      }
      Search {
        ...AISearchData
      }
      TurnUUID
    }
  }
  ${ModelData}
  ${AIResponseData}
  ${AISearchData}
`;

export const QUERY_CUSTOMER = gql`
  {
    QueryCustomer {
      ...CustomerData
    }
  }
  ${CustomerData}
`;

export const QUERY_INVOICES = gql`
  {
    QueryInvoices {
      ...InvoicesData
    }
  }
  ${InvoicesData}
`;

export const FACEBOOK_CONNECT_USER = gql`
  query FacebookConnectUser($Token: String!) {
    FacebookConnectUser(Token: $Token) {
      ...FacebookConnectResponseData
    }
  }
  ${FacebookConnectResponseData}
`;

export const GET_FB_ACCOUNTS = gql`
  query GetFBAccounts {
    GetFBAccounts {
      ...FacebookConnectResponseData
    }
  }
  ${FacebookConnectResponseData}
`;

export const FACEBOOK_CONNECT_PAGE = gql`
  query FacebookConnectPage($UUID: UUID!, $Pages: [String!]!, $Platform: String!) {
    FacebookConnectPage(UUID: $UUID, Pages: $Pages, Platform: $Platform) {
      ID
      Error
      Name
      Platform
    }
  }
`;

export const CLAIM_FLOW = gql`
  query ClaimFlow($UUID: UUID!) {
    ClaimFlow(UUID: $UUID) {
      ...OK
    }
  }
  ${OK}
`;

export const CHECK_PROMO = gql`
  query CheckPromo($Code: String!) {
    CheckPromo(Code: $Code) {
      AmountOff
      Currency
      Duration
      DurationInMonths
      ID
      Name
      PercentOff
    }
  }
`;

export const MAILCHIMP_VALIDATE = gql`
  query MailchimpValidate($APIKey: String!) {
    MailchimpValidate(APIKey: $APIKey) {
      ...MailchimpConnectResponseData
    }
  }
  ${MailchimpConnectResponseData}
`;

export const MAILCHIMP_SAVE = gql`
  query MailchimpSave($APIKey: String!, $AudienceID: String!, $AudienceName: String!) {
    MailchimpSave(APIKey: $APIKey, AudienceID: $AudienceID, AudienceName: $AudienceName) {
      ...OK
    }
  }
  ${OK}
`;

export const QUERY_PAGES = gql`
  {
    QueryPages {
      ...PagesData
    }
  }
  ${PagesData}
`;

export const QUERY_CONNECTED_AD_ACTS = gql`
  {
    QueryConnectedAdActs {
      ...AdAccountData
    }
  }
  ${AdAccountData}
`;

export const QUERY_CONNECTED_PAGES = gql`
  {
    QueryConnectedPages {
      ...PagesData
    }
  }
  ${PagesData}
`;

export const QUERY_ALL_OFF_HOURS = gql`
  query QueryAllOffHours($FlowName: String) {
    QueryAllOffHours(FlowName: $FlowName) {
      FlowName
      HoursID
      Name
      TimeZone
    }
  }
`;

export const QUERY_OFF_HOURS = gql`
  query QueryOffHours($HoursID: Int64!) {
    QueryOffHours(HoursID: $HoursID) {
      IsActive
      End
      Start
      Day
    }
  }
`;

export const QUERY_OUTCOMES = gql`
  query QueryOutcomes($FlowUUID: UUID) {
    QueryOutcomes(FlowUUID: $FlowUUID) {
      platform
      workspaceid
      type
      teamid
      channelName
      workspaceName
    }
  }
`;

export const QUERY_INTERCOM_TEAMS = gql`
  {
    QueryIntercomTeams {
      ID
      Name
    }
  }
`;

export const AUTH = gql`
  {
    Auth {
      ...OK
    }
  }
  ${OK}
`;

export const QUERY_PIXELS = gql`
  query QueryPixels($UUID: UUID!) {
    QueryPixels(UUID: $UUID) {
      ...PixelData
    }
  }
  ${PixelData}
`;

export const QUERY_ANALYTICS = gql`
  query QueryAnalytics($FlowName: String!, $StartTime: Time!, $EndTime: Time!) {
    QueryAnalytics(FlowName: $FlowName, StartTime: $StartTime, EndTime: $EndTime) {
      UsersEngaged
      UsersEntered
      UsersCompleted
      Leads
      Graph {
        ...GraphData
      }
    }
  }
  ${GraphData}
`;

export const QUERY_USER_TRANSCRIPT = gql`
  query QueryUserTranscript($GUID: String!) {
    QueryUserTranscript(GUID: $GUID) {
      ...ChatMessageData
    }
  }
  ${ChatMessageData}
`;

export const QUERY_CHAT_USERS = gql`
  query QueryChatUsers(
    $EndTime: Time!
    $First: Int!
    $FlowName: String
    $Query: String
    $StartTime: Time!
    $Username: String
    $AIOnly: Boolean
  ) {
    QueryChatUsers(
      EndTime: $EndTime
      First: $First
      FlowName: $FlowName
      Query: $Query
      StartTime: $StartTime
      Username: $Username
      AIOnly: $AIOnly
    ) {
      Users{
        ...ChatMessageData
      }
      EndCursor
      Total
      HasNextPage
    }
  }
  ${ChatMessageData}
`;

export const QUERY_USER_INPUTS = gql`
  query QueryUserInputs(
    $FlowName: String!
    $StartTime: Time!
    $EndTime: Time!
    $First: Int!
    $After: Time
  ) {
    QueryUserInputs(
      FlowName: $FlowName
      StartTime: $StartTime
      EndTime: $EndTime
      First: $First
      After: $After
    ) {
      Users {
        ...ChatMessageData
      }
      EndCursor
      Total
      HasNextPage
    }
  }
  ${ChatMessageData}
`;

export const QUERY_USER_WEB_DATA = gql`
  query QueryUserWebData($CheckAll: Boolean, $FlowName: String) {
    QueryUserWebData(CheckAll: $CheckAll, FlowName: $FlowName) {
      Name
      URL
      WebID
      Conditions {
        Type
        Value
      }
    }
  }
`;

export const QUERY_WEB_DATA_BY_ID = gql`
  query QueryWebDataByID($WebID: Int64!) {
    QueryWebDataByID(WebID: $WebID) {
      Name
      URL
      WebID
      Conditions {
        Type
        Value
      }
    }
  }
`;

export const QUERY_ALL_CUSTOMIZATION = gql`
  query QueryAllCustomization($FlowName: String) {
    QueryAllCustomization(FlowName: $FlowName) {
      ThemeID
      ThemeName
    }
  }
`;

export const QUERY_CUSTOMIZATION = gql`
  query QueryCustomization($FlowName: String, $ThemeID: Int64) {
    QueryCustomization(FlowName: $FlowName, ThemeID: $ThemeID) {
      Advanced
      Background
      Bubble
      BubbleBg
      BubbleIcon
      ButtonBg
      ButtonText
      DarkText
      GDPR
      HeaderText
      HeaderTextColor
      Icon
      Logo
      LightText
      Placement
      Primary
      Secondary
      ThemeName
      Timing
      Updated
      ThemeID
    }
  }
`;

export const QUERY_ALL_QR_CUSTOMIZATION = gql`
  query QueryAllQRCustomization($FlowName: String) {
    QueryAllQRCustomization(FlowName: $FlowName) {
      QRThemeID
      QRThemeName
    }
  }
`;

export const QUERY_QR_CUSTOMIZATION = gql`
  query QueryQRCustomization($FlowName: String, $QRThemeID: Int64) {
    QueryQRCustomization(FlowName: $FlowName, QRThemeID: $QRThemeID) {
      Background
      Complexity
      Foreground
      HasBorder
      Image
      QRThemeID
      QRThemeName
      TextIdentifier
      Updated
    }
  }
`;

export const QUERY_QR_LINKS = gql`
  query QueryQRLinks(
    $Desc: String!
    $FlowName: String!
    $Platform: String!
    $QRThemeID: Int64
    $URL: String!
  ) {
    QueryQRLinks(
      Desc: $Desc
      FlowName: $FlowName
      Platform: $Platform
      QRThemeID: $QRThemeID
      URL: $URL
    ) {
      Svg
      Png200
      Png500
      Png1000
    }
  }
`;

export const QUERY_TEMPLATES = gql`
  query QueryTemplates($TemplateID: Int64) {
    QueryTemplates(TemplateID: $TemplateID) {
      uuid
      created
      name
      desc
    }
  }
`;

export const QUERY_TEMPLATE_CATEGORIES = gql`
  query QueryTemplateCategories(
    $Partnername: String!
    $Category: Int64
    $TemplateUsername: String
  ) {
    QueryTemplateCategories(
      Partnername: $Partnername
      Category: $Category
      TemplateUsername: $TemplateUsername
    ) {
      Group
      Description
      Templates {
        Description
        FlowName
        IconURL
        ID
        Name
        PreviewURL
        Type
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  query SendEmail($PartnerUsername: String, $FlowName: String, $PurchaseID: String, $Type: String!, $Recipients: String) {
    SendEmail(PartnerUsername: $PartnerUsername, FlowName: $FlowName, PurchaseID: $PurchaseID, Type: $Type, Recipients: $Recipients) {
      ...OK
    }
  }
  ${OK}
`;

// mutations

export const CREATE_CONTENT = gql`
  mutation CreateContent($Name: String!, $IsGroup: Boolean!) {
    CreateContent(Name: $Name, IsGroup: $IsGroup) {
      ...OK
    }
  }
  ${OK}
`;

export const CREATE_PROCESSING_PROJECT = gql`
  mutation CreateProcessingProject($Project: ProcessingProjectInput!) {
    CreateProcessingProject(Project: $Project) {
      ...OK
    }
  }
  ${OK}
`;

export const DELETE_CONTENT = gql`
  mutation DeleteContent($ModelID: Int64!) {
    DeleteContent(ModelID: $ModelID) {
      uuid
      created
      name
      desc
      type
      platforms
      active
    }
  }
`;

export const DELETE_OFF_HOURS = gql`
  mutation DeleteOffHours($HoursID: Int64!) {
    DeleteOffHours(HoursID: $HoursID) {
      ...OK
    }
  }
  ${OK}
`;

export const DUPLICATE_GROUP = gql`
  mutation DuplicateGroup($GroupID: Int64!, $IsProject: Boolean!, $Name: String!, $Platform: String!, $PlatformID: String!) {
    DuplicateGroup(GroupID: $GroupID, IsProject: $IsProject, Name: $Name, Platform: $Platform, PlatformID: $PlatformID) {
      ...OK
    }
  }
  ${OK}
`;

export const SET_PROFILE_INFORMATION = gql`
  mutation SetProfileInformation(
    $FirstName: String
    $LastName: String
    $Company: String
    $CompanyWebsite: String
    $CompanySize: String
    $PrivatePreview: Boolean
  ) {
    SetProfileInformation(
      FirstName: $FirstName
      LastName: $LastName
      Company: $Company
      CompanyWebsite: $CompanyWebsite
      CompanySize: $CompanySize
      PrivatePreview: $PrivatePreview
    ) {
      ...OK
    }
  }
  ${OK}
`;

export const SET_CONTENT_GROUP = gql`
  mutation SetContentGroup($GroupID: Int64!, $Type: String!, $UUID: UUID!) {
    SetContentGroup(GroupID: $GroupID, Type: $Type, UUID: $UUID) {
      ...OK
    }
  }
  ${OK}
`;

export const SET_FLOW_OFF_HOURS = gql`
  mutation SetFlowOffHours($FlowName: String!, $HoursID: Int64!) {
    SetFlowOffHours(FlowName: $FlowName, HoursID: $HoursID) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_AI_MODEL = gql`
  mutation UpdateAIModel($Model: AIModelInput!) {
    UpdateAIModel(Model: $Model) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_PROJECT_NAME = gql`
  mutation UpdateProject($Name: String, $PurchaseID: String!) {
    UpdateProject(Name: $Name, PurchaseID: $PurchaseID) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_AI_MODEL_GROUP = gql`
  mutation UpdateAIModelGroup($Group: AIModelInput!, $Confidence: Int64, $Content: [AIModelContentInput!]!) {
    UpdateAIModelGroup(Group: $Group, Confidence: $Confidence, Content: $Content) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_OFF_HOURS = gql`
  mutation UpdateOffHours(
    $FlowName: String
    $Graph: String
    $HoursID: Int64
    $Name: String!
    $TimeZone: String!
    $OffHours: [InputOffHoursSetting!]!
  ) {
    UpdateOffHours(
      FlowName: $FlowName
      Graph: $Graph
      HoursID: $HoursID
      Name: $Name
      TimeZone: $TimeZone
      OffHours: $OffHours
    ) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_SMS = gql`
  mutation UpdateSMS($Name: String!, $NewPhones: [String!]!, $OldPhones: [String!]!) {
    UpdateSMS(Name: $Name, NewPhones: $NewPhones, OldPhones: $OldPhones) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_ONBOARDING_SMS = gql`
  mutation UpdateOnboardingSMS($Phones: [String!]!) {
    UpdateOnboardingSMS(Phones: $Phones) {
      ...OK
    }
  }
  ${OK}
`;

export const SETUP_HOSTING = gql`
  mutation SETUP_HOSTING($Platform: String!) {
    SetupHosting(Platform: $Platform) {
      ...OK
    }
  }
  ${OK}
`;

export const VERIFY_URL = gql`
  mutation VERIFY_URL($URL: String!) {
    VerifyURL(URL: $URL) {
      ...OK
    }
  }
  ${OK}
`;

export const START_TRAINING = gql`
  mutation START_TRAINING($Depth: Int64, $Engines: [String!]!, $ModelID: Int64, $Name: String, $PurchaseID: String, $Updates: [FileUpdateInput], $URLs: [String]) {
    StartTraining(Depth: $Depth, Engines: $Engines, ModelID: $ModelID, Name: $Name, PurchaseID: $PurchaseID, Updates: $Updates, URLs: $URLs) {
      ...OK
    }
  }
  ${OK}
`;

export const SAVE_ONBOARDING_FLOW = gql`
  mutation SaveOnboardingFlow($Platform: String, $TemplateID: Int64, $FlowName: String) {
    SaveOnboardingFlow(Platform: $Platform, TemplateID: $TemplateID, FlowName: $FlowName) {
      Name
      URL
      WebID
      Conditions {
        Type
        Value
      }
    }
  }
`;

export const SAVE_DRAFT_FLOW = gql`
  mutation SaveDraftFlow(
    $Graph: String!
    $Name: String
    $Desc: String!
    $PlatformID: String!
    $Platform: String!
    $IsDupe: Boolean!
    $IsProject: Boolean!
    $IsTemplate: Boolean!
  ) {
    SaveDraftFlow(
      Graph: $Graph
      Name: $Name
      Desc: $Desc
      PlatformID: $PlatformID
      Platform: $Platform
      IsDupe: $IsDupe
      IsProject: $IsProject
      IsTemplate: $IsTemplate
    ) {
      ...GraphData
    }
  }
  ${GraphData}
`;

export const ARCHIVE_FLOW = gql`
  mutation ArchiveFlow($UUID: UUID!) {
    ArchiveFlow(UUID: $UUID) {
      ...OK
    }
  }
  ${OK}
`;

export const PUBLISH_FLOW = gql`
  mutation PublishFlow($UUID: UUID!, $PixelID: String, $Platform: String!, $PlatformID: String) {
    PublishFlow(UUID: $UUID, PixelID: $PixelID, Platform: $Platform, PlatformID: $PlatformID) {
      CampaignID
      AdSetID
      URL
    }
  }
`;

export const FACEBOOK_CONNECT_AD_ACCOUNT = gql`
  mutation FacebookConnectAdAccount($AdActs: [InputAdAct!]!) {
    FacebookConnectAdAccount(AdActs: $AdActs) {
      ...FacebookConnectResponseData
    }
  }
  ${FacebookConnectResponseData}
`;

export const SET_ONBOARDED = gql`
  mutation SetOnboarded {
    SetOnboarded {
      ...OK
    }
  }
  ${OK}
`;

export const ADMIN_CREATE_USER = gql`
  mutation AdminCreateUser($First: String!, $Last: String!, $Email: String!, $Password: String!) {
    AdminCreateUser(First: $First, Last: $Last, Email: $Email, Password: $Password) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_ONBOARDING = gql`
  mutation UpdateOnboarding($Step: Int!, $FlowUUID: UUID, $Platform: String) {
    UpdateOnboarding(Step: $Step, FlowUUID: $FlowUUID, Platform: $Platform) {
      ...OK
    }
  }
  ${OK}
`;

export const SAVE_INTERCOM = gql`
  mutation SaveIntercom($TeamID: String!) {
    SaveIntercom(TeamID: $TeamID) {
      ...OK
    }
  }
  ${OK}
`;

export const RESET_PSID = gql`
  mutation ResetPSID($First: String!, $Last: String!, $UUID: UUID!) {
    ResetPSID(First: $First, Last: $Last, UUID: $UUID)
  }
`;

export const RESET_TEST_ACCOUNT = gql`
  mutation ResetTestAccount($Username: String, $Type: Int!, $ShouldDelete: Boolean!) {
    ResetTestAccount(Username: $Username, Type: $Type, ShouldDelete: $ShouldDelete) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_FLOW = gql`
  mutation UpdateFlow($UUID: UUID!, $PlatformID: String!) {
    UpdateFlow(UUID: $UUID, PlatformID: $PlatformID) {
      ...GraphData
    }
  }
  ${GraphData}
`;

export const UPDATE_ONBOARDING_FLOW = gql`
  mutation UpdateOnboardingFlow($UUID: UUID!, $PlatformID: String!) {
    UpdateOnboardingFlow(UUID: $UUID, PlatformID: $PlatformID) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_USER_WEB_DATA = gql`
  mutation UpdateUserWebData(
    $FlowUUID: UUID
    $IsOnboarding: Boolean!
    $UserWebData: [InputWebData!]!
  ) {
    UpdateUserWebData(UserWebData: $UserWebData, IsOnboarding: $IsOnboarding, FlowUUID: $FlowUUID) {
      Name
      URL
      WebID
      Conditions {
        Type
        Value
      }
    }
  }
`;

export const DELETE_CUSTOMIZATION = gql`
  mutation DeleteCustomization($ThemeID: Int64!) {
    DeleteCustomization(ThemeID: $ThemeID) {
      ...OK
    }
  }
  ${OK}
`;

export const DELETE_QR_CODE_CUSTOMIZATION = gql`
  mutation DeleteQRCustomization($QRThemeID: Int64!) {
    DeleteQRCustomization(QRThemeID: $QRThemeID) {
      ...OK
    }
  }
  ${OK}
`;

export const DELETE_USER_WEB_DATA = gql`
  mutation DeleteUserWebData($WebID: Int64!) {
    DeleteUserWebData(WebID: $WebID) {
      ...OK
    }
  }
  ${OK}
`;

export const SET_FLOW_CUSTOMIZATION = gql`
  mutation SetFlowCustomization($FlowName: String!, $ThemeID: Int64!) {
    SetFlowCustomization(FlowName: $FlowName, ThemeID: $ThemeID) {
      ...OK
    }
  }
  ${OK}
`;

export const SET_FLOW_QR_CUSTOMIZATION = gql`
  mutation SetFlowQRCustomization($FlowName: String!, $QRThemeID: Int64!) {
    SetFlowQRCustomization(FlowName: $FlowName, QRThemeID: $QRThemeID) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_CUSTOMIZATION = gql`
  mutation UpdateCustomization(
    $Advanced: String!
    $Background: String!
    $Bubble: String!
    $BubbleBg: String!
    $BubbleIcon: String!
    $ButtonBg: String!
    $ButtonText: String!
    $DarkText: String!
    $GDPR: Boolean!
    $HeaderText: String!
    $HeaderTextColor: String
    $Icon: String!
    $LightText: String!
    $Logo: String!
    $FlowName: String
    $Placement: String
    $Primary: String!
    $Secondary: String!
    $ThemeID: Int64
    $ThemeName: String!
    $Timing: Float!
  ) {
    UpdateCustomization(
      Advanced: $Advanced
      Background: $Background
      Bubble: $Bubble
      BubbleBg: $BubbleBg
      BubbleIcon: $BubbleIcon
      ButtonBg: $ButtonBg
      ButtonText: $ButtonText
      DarkText: $DarkText
      GDPR: $GDPR
      HeaderText: $HeaderText
      HeaderTextColor: $HeaderTextColor
      Icon: $Icon
      LightText: $LightText
      Logo: $Logo
      Placement: $Placement
      Primary: $Primary
      Secondary: $Secondary
      ThemeName: $ThemeName
      Timing: $Timing
      FlowName: $FlowName
      ThemeID: $ThemeID
    ) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_QR_CUSTOMIZATION = gql`
  mutation UpdateQRCustomization(
    $FlowName: String
    $QRThemeID: Int64
    $QRThemeName: String!
    $Background: String
    $Complexity: String
    $Foreground: String
    $HasBorder: Boolean
    $Image: String
    $TextIdentifier: String
  ) {
    UpdateQRCustomization(
      FlowName: $FlowName
      QRThemeID: $QRThemeID
      QRThemeName: $QRThemeName
      Background: $Background
      Complexity: $Complexity
      Foreground: $Foreground
      HasBorder: $HasBorder
      Image: $Image
      TextIdentifier: $TextIdentifier
    ) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_FLOW_DESCRIPTION = gql`
  mutation UpdateFlowDescription($UUID: UUID!, $Description: String!) {
    UpdateFlowDescription(UUID: $UUID, Description: $Description) {
      ...OK
    }
  }
  ${OK}
`;

export const UPDATE_BILLING = gql`
  mutation UpdateBilling($BillingName: String, $BillingEmail: String, $BillingDetails: String) {
    UpdateBilling(
      BillingName: $BillingName
      BillingEmail: $BillingEmail
      BillingDetails: $BillingDetails
    ) {
      ...CustomerData
    }
  }
  ${CustomerData}
`;

export const UPDATE_SOURCE = gql`
  mutation UpdateSource($Token: String!) {
    UpdateSource(Token: $Token) {
      ...CustomerData
    }
  }
  ${CustomerData}
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $PurchaseID: String!
    $Coupon: String
    $Token: String
    $BillingName: String
    $BillingEmail: String
    $TrialDays: Int64
    $DemoUUID: String
    $Pending: Boolean
  ) {
    UpdateSubscription(
      Coupon: $Coupon
      PurchaseID: $PurchaseID
      Token: $Token
      BillingName: $BillingName
      BillingEmail: $BillingEmail
      TrialDays: $TrialDays
      DemoUUID: $DemoUUID
      Pending: $Pending
    ) {
      ...CustomerData
    }
  }
  ${CustomerData}
`;

export const UPDATE_SUBSCRIPTION_STATUS = gql`
  mutation UpdateSubscriptionStatus(
    $CreateFlows: Boolean
    $PartnerUsername: String
    $Phones: [String!]
    $PurchaseID: String!
    $TemplateID: Int64
    $TemplateType: String
    $URL: String
  ) {
    UpdateSubscriptionStatus(
      CreateFlows: $CreateFlows
      PartnerUsername: $PartnerUsername
      Phones: $Phones
      PurchaseID: $PurchaseID
      TemplateID: $TemplateID
      TemplateType: $TemplateType
      URL: $URL
    ) {
      ...OK
    }
  }
  ${OK}
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($PurchaseID: String) {
    CancelSubscription(PurchaseID: $PurchaseID) {
      ...CustomerData
    }
  }
  ${CustomerData}
`;