import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { QUERY_CUSTOMER } from '../constants/schema';

export const AccountContext = createContext();

const AccountProvider = ({ children, stripe }) => {
  // eslint-disable-next-line
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);
  const [customerData, setCustomerData] = useState('');
  const [accountLoad, setAccountLoad] = useState({});
  const { loading: customerLoading, error: customerError, data, refetch: queryCustomerRefetch } = useQuery(
    QUERY_CUSTOMER,
  );

  useEffect(() => {
    if (data?.QueryCustomer) {
      setCustomerData(data.QueryCustomer);
    }
  }, [data]);

  return (
    <AccountContext.Provider
      value={{
        accountLoad,
        customerData,
        customerError,
        customerLoading,
        isAccountDisabled,
        setAccountLoad,
        setCustomerData,
        queryCustomerRefetch,
        stripe,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
