import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../context/AuthContext';
import { OnboardingContext } from '../../../context/OnboardingContext';
import { convertPxToRem } from '../../../utils/convertPxToRem';
import { openScheduler } from '../../../utils/openScheduler';
import { navActiveStep } from '../../../constants/onboarding';
import { SubscriptionContext } from '../../../context/SubscriptionContext';

import theme from '../../../theme';
import { grantAccess } from '../../../theme/theme';

const NavigationLinks = ({ handleOnClick }) => {
  const { isAuthed, isLoading, logout } = useAuth();
  const { isOnboarding, onboardingInfo, step } = useContext(OnboardingContext);
  const { subscriptionsData, accountType, isSubscriptionDependencyLoaded } =
    useContext(SubscriptionContext);
  const [navLinks, setNavLinks] = useState(null);
  const [onboardingNav, setOnboardingNav] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleLogin = useCallback(() => {
    if (handleOnClick) {
      handleOnClick();
    }

    if (location.pathname !== '/') {
      localStorage.setItem('location', location.pathname);
    }

    history.push('/auth/signin');
  }, [handleOnClick, history, location]);

  const handleClick = useCallback(
    e => {
      if (handleOnClick) {
        handleOnClick();
      }
    },
    [handleOnClick],
  );

  const openHelp = useCallback(() => {
    if (theme?.help) {
      window.open(theme.help, '_blank');
    }
  }, []);

  const openChat = useCallback(() => {
    if (window.toggleChat) {
      window.toggleChat();
    }
  }, []);

  useEffect(() => {
    if (isOnboarding && step < navActiveStep && location.pathname !== '/reset') {
      setOnboardingNav(true);
    } else {
      setOnboardingNav(false);
    }
  }, [isOnboarding, location.pathname, onboardingInfo, step]);

  useEffect(() => {
    // if isLoading, empty nav links

    if (!isLoading && isSubscriptionDependencyLoaded) {
      // if not authed, show login/signup EXCEPT
      // with EyeLevel theme and on demo pages
      if (isAuthed === false) {
        if (theme?.nav?.demoSchedule && location.pathname.indexOf('demo') > -1) {
          setNavLinks(<Buttons onClick={openScheduler}>{theme.nav.demoSchedule}</Buttons>);
        } else if (!theme?.nav?.hideLogin) {
          setNavLinks(
            <Links to="/" onClick={handleLogin}>
              Login/Signup
            </Links>,
          );
        }
      } else if (onboardingNav) {
        setNavLinks(
          <>
            {!theme?.nav?.hideChat && (
              <Buttons onClick={openChat}>
                <Icon src="https://cdn.cashbot.ai/assets/chat-nav.svg" />
                CHAT
              </Buttons>
            )}
            {!theme?.nav?.hideHelp && <Buttons onClick={openHelp}>HELP</Buttons>}
            {!theme?.nav?.hideLogout && (
              <Buttons isRight onClick={logout}>
                LOG OUT
              </Buttons>
            )}
          </>,
        );
      } else {
        setNavLinks(
          <>
            {accountType === 'translation' && (
              <>
                <Links isactive={(location.pathname === '/' || location.pathname === '/translation') ? 1 : 0} onClick={handleClick} to="/translation">
                    DASHBOARD
                  </Links>
                <Buttons isRight onClick={logout}>
                  LOG OUT
                </Buttons>
              </>
            )}
            {accountType === 'default' && (
              <>
                {grantAccess('nav-dashboard', { subscriptions: subscriptionsData }) && (
                  <Links isactive={location.pathname === '/' ? 1 : 0} onClick={handleClick} to="/">
                    DASHBOARD
                  </Links>
                )}
                {grantAccess('nav-ai', { subscriptions: subscriptionsData }) && (
                  <Links
                    isactive={location.pathname === '/inbox' ? 1 : 0}
                    onClick={handleClick}
                    to="/inbox"
                  >
                    INBOX
                  </Links>
                )}
                {!theme?.nav?.hideChat && (
                  <Buttons onClick={openChat}>
                    <Icon src="https://cdn.cashbot.ai/assets/chat-nav.svg" />
                    CHAT
                  </Buttons>
                )}
                {!theme?.nav?.hideHelp && <Buttons onClick={openHelp}>HELP</Buttons>}
                {grantAccess('nav-account', { subscriptions: subscriptionsData }) && (
                  <Links
                    isactive={location.pathname === '/account' ? 1 : 0}
                    onClick={handleClick}
                    to="/account"
                  >
                    ACCOUNT
                  </Links>
                )}
                {!theme?.nav?.hideLogout && (
                  <Buttons isRight onClick={logout}>
                    LOG OUT
                  </Buttons>
                )}
              </>
            )}
          </>,
        );
      }
    }
  }, [
    handleClick,
    handleLogin,
    isAuthed,
    isLoading,
    isOnboarding,
    location,
    logout,
    onboardingNav,
    openChat,
    openHelp,
    step,
    subscriptionsData,
    accountType,
    isSubscriptionDependencyLoaded,
  ]);

  return navLinks;
};

const Buttons = styled.button`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(30)};
  margin-bottom: ${({ isRight }) => !isRight && convertPxToRem(30)};
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: ${convertPxToRem(18)};
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 782px) {
    margin: ${({ isRight }) =>
      isRight ? `${convertPxToRem(7)} 0 0` : `${convertPxToRem(7)} ${convertPxToRem(16)} 0 0`};
    padding: ${convertPxToRem(6)} ${convertPxToRem(12)} ${convertPxToRem(8)};
    background-color: ${({ isactive }) => isactive && 'var(--background-light)'};
    font-size: ${convertPxToRem(14)};
  }
`;

const Icon = styled.img`
  height: ${convertPxToRem(16)};
  margin-top: ${convertPxToRem(2)};
  margin-right: ${convertPxToRem(8)};
`;

const Links = styled(Link)`
  box-sizing: border-box;
  height: ${convertPxToRem(30)};
  margin-bottom: ${convertPxToRem(30)};
  border-radius: ${convertPxToRem(5)};
  font-size: ${convertPxToRem(18)};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 782px) {
    margin: ${convertPxToRem(7)} ${convertPxToRem(16)} 0 0;
    padding: ${convertPxToRem(6)} ${convertPxToRem(12)} ${convertPxToRem(8)};
    background-color: ${({ isactive }) => isactive && 'var(--background-light)'};
    font-size: ${convertPxToRem(14)};
  }
`;

export default NavigationLinks;
