import styled, { css } from 'styled-components';

import { Input, PrimaryButton } from '../../common/ComponentsLibrary';

import { convertPxToRem } from '../../../utils/convertPxToRem';

export const ButtonWrapper = styled.div`
  margin-top: ${({ noTop }) => !noTop && convertPxToRem(32)};
  margin: ${({ margin }) => margin};
  display: flex;
  flex-direction: row;
  justify-content: ${({ spaceBetween }) => spaceBetween && 'space-between'};

  button {
    padding-left: ${convertPxToRem(3)};
    padding-right: ${convertPxToRem(3)};
    transition: 0.1s ease-in-out;

    :disabled {
      opacity: 0.5;
    }
  }

  @media (max-width: 900px) {
    flex-direction: ${({ column }) => column && 'column'};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 782px) {
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ normal, success }) => success ? 'var(--success-green)' : (normal ? 'var(--main-dark-blue)' : 'var(--error-red)')};
  position: absolute;
  bottom: ${({ spaceBottom }) => (spaceBottom ? spaceBottom : convertPxToRem(-4))};
`;

export const GreenText = styled.button`
  color: var(--main-blue);
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: ${({ margin }) => margin && margin};
`;

export const Icon = styled.img`
  width: 100%;
  margin-right: ${convertPxToRem(16)};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const LeftContent = styled.div`
  width: ${({oneColumn}) => oneColumn ? '100%' : '50vw'};

  @media (max-width: 782px) {
    align-self: center;
    padding: ${convertPxToRem(16)};
    width: 100%;
  }
`;

export const LeftErrorContent = styled.div`
  max-width: ${convertPxToRem(800)};

  @media (max-width: 782px) {
    align-self: center;
    padding: ${convertPxToRem(16)};
    width: 92%;
  }

  ${({ notEnoughContent }) =>
    notEnoughContent &&
    css`
      display: flex;
      flex-direction: row;

      @media (max-width: 478px) {
        flex-direction: column;

        ${SVGLeftWrapper} {
          margin-top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    `}
`;

export const MeetingBot = styled.div`
  padding: ${convertPxToRem(0, 32, 24, 32)};
  display:flex;
  justify-content:center;
`

export const MeetingTop = styled.div`
  background-color:  var(--main-light-blue);
  padding: ${convertPxToRem(32)};
`;

export const PageHeader = styled.h2`
  color: var(--main-dark-blue);
  font-size: ${convertPxToRem(30)};
  margin-bottom: ${({ mb }) => mb ? mb : convertPxToRem(10)};

  @media (max-width: 782px) {
    margin-bottom: ${convertPxToRem(26)};
  }
`;

export const RightContent = styled.div`
  display: flex;
  margin-left: ${convertPxToRem(32)};
  justify-content: flex-end;
  align-items: flex-start;
  width: ${({oneColumn}) => oneColumn ? '0' : '35vw'};

  @media (max-width: 782px) {
    align-self: center;
    margin: ${convertPxToRem(16, 0)};
    width: ${({oneColumn}) => oneColumn ? '0' : '92%'};
  }
`;

export const RightErrorContent = styled.div`
  img {
    max-height: ${convertPxToRem(320)};
  }

  @media (max-width: 782px) {
    align-self: center;
    margin-top: ${convertPxToRem(16)};
    width: 92%;

    img {
      display: none;
    }
  }
`;

export const ScheduleMeeting = styled.div`
  margin-top: ${convertPxToRem(32)};
  width: ${convertPxToRem(448)};
  margin-left: ${convertPxToRem(64)};
  border-radius: ${convertPxToRem(8)};
  border: solid ${convertPxToRem(1)} var(--ai-right-header);;
  @media (max-width: 910px) {
    width: ${convertPxToRem(288)};
  }

  @media (max-width: 782px) {
    margin: 0;
    margin-top: ${convertPxToRem(8)};
    width: 100%;
    border-radius: 0;
  }
`;

export const StartOverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  > button {
    max-width: ${convertPxToRem(300)};
    padding-left: 0;
    justify-content: flex-start;
  }

  ${({ notEnoughContent }) =>
    notEnoughContent &&
    css`
      > button {
        justify-content: center;
        margin-top: 0;
      }
    `}
`;

export const StyledInput = styled(Input)`
  margin-bottom: ${convertPxToRem(36)};
`;

export const SubmitButton = styled(PrimaryButton)`
  margin-left: ${({ marginAuto }) => (marginAuto ? 'auto' : '')};
  margin-right: ${({ marginAuto }) => (marginAuto ? 'auto' : '')};
  margin-top: ${convertPxToRem(32)};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const SVGLeftWrapper = styled.div`
  margin-top: ${convertPxToRem(32)};
  margin-right: ${convertPxToRem(16)};

  > img {
    height: ${convertPxToRem(100)};
  }
`;

export const Text = styled.p`
  margin: 0;
  margin-top: ${convertPxToRem(8)};
  margin-bottom: ${convertPxToRem(16)};
  margin: ${({ margin }) => margin};
`;

export const TextHeader = styled.h3`
  margin: 0;
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  font-weight: bold;
  font-size: ${({ fs }) => fs};
`;

export const TextRightWrapper = styled.div``;

export const Wrapper = styled.div`
  min-height: calc(100vh - ${convertPxToRem(66)});
  max-width: ${convertPxToRem(1980)};
  padding: ${({ noMessageBar }) => noMessageBar ? convertPxToRem(0, 48) : convertPxToRem(32, 48, 48, 48)};
  min-height: ${({ error }) => error && '0'};
  position: ${({ isOnboarding }) => isOnboarding && 'absolute'};
  top: ${({ isOnboarding }) => isOnboarding && '0'};
  bottom: ${({ isOnboarding }) => isOnboarding && '0'};
  left: ${({ isOnboarding }) => isOnboarding && '0'};
  right: ${({ isOnboarding }) => isOnboarding && '0'};
  display: ${({ isOnboarding }) => isOnboarding && 'flex'};
  justify-content: ${({ isOnboarding }) => isOnboarding && 'center'};

  @media (max-width: 782px) {
    padding: ${({ error, noMessageBar }) => noMessageBar ? (error ? convertPxToRem(0, 16) : '0') : convertPxToRem(16, 24, 24, 24)};
  }

  @media (max-width: 540px) {
    padding: ${({ error, noMessageBar }) => noMessageBar ? (error ? convertPxToRem(0, 16) : '0') : convertPxToRem(8, 16, 16, 16)};
  }

  @media (max-width: 400px) {
    padding: ${({ error, noMessageBar }) => noMessageBar ? (error ? convertPxToRem(0, 16) : '0') : convertPxToRem(8)};
  }

  h2,
  h3 {
    color: var(--main-dark-blue);
  }
`;