const GLOBAL_FONT_SIZE = 16;

export const convertPxToRem = (...args) =>
  args.reduce((accumulator, currentValue) => {
    if (currentValue === 'auto') {
      return (accumulator += 'auto ');
    }

    if (!currentValue) {
      return (accumulator += '0 ');
    }

    return (accumulator += `${currentValue / GLOBAL_FONT_SIZE}rem `);
  }, '');
