export const hasAIModel = (subscriptions) => {
  return subscriptions.find((curr) => {
    return curr?.Model?.ModelID;
  });
};

export const hasWebCrawl = (subscriptions) => {
  return subscriptions.find((curr) => {
    return isWebCrawl(curr);
  });
};

export const hasWordpress = (subscriptions) => {
  return subscriptions.find((curr) => {
    return isWordpress(curr);
  });
};

export const isWebCrawl = (sub) => {
  return sub?.Subscription?.AISource === 'webcrawl';
}

export const isWordpress = (sub) => {
  return sub?.Subscription?.AISource === 'wordpress';
};

export const initialSetupComplete = (subscriptions) => {
  return subscriptions.find((curr) => {
    return curr?.Flow &&
      curr?.WebData &&
      curr?.Model?.ModelID &&
      curr?.Subscription?.PurchaseID;
  });
}

export const licenseType = (sub) => {
  switch(sub?.Subscription?.AISource) {
    case 'webcrawl':
      return 'Not Wordpress';
    case 'wordpress':
      return 'Wordpress';
    default:
      return 'Unknown';
  }
}

export const licenseTypes = (subscriptions) => {
  const uniques = {};
  const licenses = [];
  if (subscriptions?.length) {
    subscriptions.forEach((sub) => {
      if (sub?.Subscription?.AISource && !uniques[sub.Subscription.AISource]) {
        uniques[sub.Subscription.AISource] = true;
        licenses.push(sub);
      }
    });
  }

  return licenses;
}

export const processSubscription = (subscription) => {
  if (subscription?.Metadata) {
    try {
      subscription.Metadata = JSON.parse(subscription.Metadata);
    } catch(e) {}
  }
  return subscription;
}

export const processSubscriptions = (subscriptions) => {
  return subscriptions.map((curr) => {
    if (curr?.Subscription?.Metadata) {
      try {
        curr.Subscription.Metadata = JSON.parse(curr.Subscription.Metadata);
      } catch(e) {}
    }
    return curr;
  });
}