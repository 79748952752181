import gql from 'graphql-tag';

export const AIButtonData = gql`
  fragment AIButtonData on AIButton {
    FlowUUID
    Payload
    Title
    TurnID
    Type
  }
`;

export const AIResponseMessageData = gql`
  fragment AIResponseMessageData on AIResponseMessage {
    Category
    Delay
    Message
  }
`;

export const AIResponseMetaData = gql`
  fragment AIResponseMetaData on AIResponseMeta {
    CalcScore
    Calc2Score
    FeedbackType
    RawScore
    Type
  }
`;

export const AIResponseData = gql`
  fragment AIResponseData on AIResponse {
    Buttons {
      ...AIButtonData
    }
    CategoryID
    Category
    FlowName
    FlowUUID
    Messages {
      ...AIResponseMessageData
    }
    Meta {
      ...AIResponseMetaData
    }
    Type
    TurnID
  }
  ${AIButtonData}
  ${AIResponseMessageData}
  ${AIResponseMetaData}
`;

export const AIAnswerMetaData = gql`
  fragment AIAnswerMetaData on AIAnswerMeta {
    Response {
      ...AIResponseData
    }
    URL
  }
  ${AIResponseData}
`;

export const ModelCopy = gql`
  fragment ModelCopy on AIModelCopy {
    EmptyResponseText
    ReadMoreText
  }
`;

export const ModelExample = gql`
  fragment ModelExample on AIModelExample {
    Agent
    User
  }
`;

export const ModelSettings = gql`
  fragment ModelSettings on AIModelSettings {
    BotName
    ChatEngine
    ChatInstruction
    CompanyName
    CompletionEngine
    CompletionInstruction
    Confidence
    ContextTokens
    DefaultCopy {
      ...ModelCopy
    }
    DoButtons
    DoClassify
    DoEntity
    DoParty
    DoSearch
    DoSupport
    Examples {
      ...ModelExample
    }
    Feedback
    PreProcessors
    ProfanityFilter
    PostProcessors
    ReadMore
    ResponseTokens
    SearchEngine
    SessionTurns
    ShowSource
    TranscriptTokens
  }
  ${ModelCopy}
  ${ModelExample}
`;

export const FileUpdateData = gql`
  fragment FileUpdateData on FileUpdate {
    AnswerText
    AnswerResponse{
      ...AIResponseData
    }
    Cap
    ContentURL
    Depth
    DocumentID
    ModelID
    Name
    OperationType
    Questions
    Source
    Status
    StatusMessage
    Text
    TrainURL
  }
  ${AIResponseData}
`;

export const TrainFileData = gql`
  fragment TrainFileData on TrainFile {
    AnswerText
    Annotation
    AnnotationStatus
    AnswerResponse{
      ...AIResponseData
    }
    ContentURL
    DocumentID
    ModelID
    Name
    OperationType
    Questions
    Source
    Status
    StatusMessage
    Text
    TextURL
    TrainURL
  }
  ${AIResponseData}
`;

export const ModelContentData = gql`
  fragment ModelContentData on AIModelContent {
    KitID
    ModelID
    Name
    Priority
    Type
  }
`;

export const ModelEngine = gql`
  fragment ModelEngine on AIModelEngine {
    Disabled
    EngineID
    EngineName
    InstructTokens
    Service
    TranscriptTokens
  }
`;

export const ProcessingProject = gql`
  fragment ProcessingProject on ProcessingProject {
    Deadline
    ModelID
    Name
    ProjectID
    Source
    Target
    Type
  }
`;

export const ModelData = gql`
  fragment ModelData on AIModel {
    AISource
    ChatEngine{
      ...ModelEngine
    }
    Content{
      ...ModelContentData
    }
    DemoUUID
    Files{
      ...TrainFileData
    }
    KitID
    ModelID
    ModelType
    Name
    Project {
      ...ProcessingProject
    }
    Purpose
    Settings {
      ...ModelSettings
    }
    UrlTraining{
      metadata
      qualityRating
      status
      taskId
    }
  }
  ${ModelEngine}
  ${ModelContentData}
  ${TrainFileData}
  ${ProcessingProject}
  ${ModelSettings}
`;

export const AISearchResultData = gql`
  fragment AISearchResultData on AISearchResult {
    DocumentID
    KitID
    Metadata {
      ...AIAnswerMetaData
    }
    ModelID
    Priority
    Score
    Text
    Used
  }
  ${AIAnswerMetaData}
`;

export const AISearchData = gql`
  fragment AISearchData on AISearch {
    Priority
    Prompt
    Searches {
      Priority
      Prompt
      SearchResults {
        ...AISearchResultData
      }
      TopScore
      TopMeta {
        ...AIAnswerMetaData
      }
      TotalDocuments
      Type
    }
    SearchResults {
      ...AISearchResultData
    }
    TopScore
    TopMeta {
      ...AIAnswerMetaData
    }
    TotalDocuments
    Type
  }
  ${ModelData}
  ${AISearchResultData}
  ${AIAnswerMetaData}
`;

export const ChatMessageData = gql`
  fragment ChatMessageData on ChatMessage {
    Date
    Email
    Name
    GUID
    Message
    Phone
    Ref
    Sender
    SessionUUID
    TurnUUID
  }
`;

export const ChatMessagesData = gql`
  fragment ChatMessagesData on ChatMessages {
    Total
    ChatMessages {
      ...ChatMessageData
    }
    EndCursor
    HasNextPage
  }

  ${ChatMessageData}
`;

export const PixelData = gql`
  fragment PixelData on FacebookPixel {
    Name
    AccountID
    PixelID
    ConversionID
  }
`;

export const AdAccountData = gql`
  fragment AdAccountData on FacebookAdAccount {
    Name
    ID
    Business
    Pixels {
      ...PixelData
    }
  }
  ${PixelData}
`;

export const PagesData = gql`
  fragment PagesData on FacebookPage {
    Name
    ID
    Picture
  }
`;

export const InvoiceData = gql`
  fragment InvoiceData on Invoice {
    ID
    Created
    Amount
    Currency
    URL
    Paid
  }
`;

export const SubscriptionData = gql`
  fragment SubscriptionData on Subscription {
    AISource
    Anchor
    CancelAt
    Currency
    CustomerUsername
    HasOnboarding
    Interval
    LiveChatMetadata
    Metadata
    ModelID
    Name
    PlanID
    Price
    PurchaseID
    TemplateID
    TrialEnd
    TrialStart
    WebDomain
    WebID
  }
`;

export const TranslationLanguage = gql`
  fragment TranslationLanguage on TranslationLanguage {
    Description
    LanguageID
    Name
    ProcessorID
    Username
  }
`;

export const CustomerData = gql`
  fragment CustomerData on Customer {
    BillingName
    BillingEmail
    BillingDetails
    PaymentSource {
      Object
      Brand
      Ending
    }
    Subscription {
      ...SubscriptionData
    }
  }
  ${SubscriptionData}
`;

export const InvoicesData = gql`
  fragment InvoicesData on Invoices {
    Invoices {
      ...InvoiceData
    }
  }
  ${InvoiceData}
`;

export const AudiencesData = gql`
  fragment AudiencesData on MailchimpAudience {
    Name
    ID
  }
`;

export const NodeData = gql`
  fragment NodeData on Node {
    id
    group
    label
    title
    image
    meta {
      anchor
      categories
      replies
      type
      title
      value
      desc
      imageURL
      attr {
        key
        value
      }
      label {
        subject
        name
      }
      alert {
        workspaceID
        teamID
        platform
        alert
        shadow
      }
      cards {
        title
        value
        desc
        imageURL
        params
        buttons {
          label
          meta {
            categories
            type
            value
            params
            submit
          }
        }
      }
      conversion {
        url
        enrich
        integration
        headers {
          key
          value
        }
        payload
        method
        name
      }
      buttons {
        label
        meta {
          categories
          type
          value
          params
          submit
        }
      }
      params
      submit
    }
  }
`;

export const EdgeData = gql`
  fragment EdgeData on Edge {
    from
    to
    label
    value
    arrows {
      to {
        enabled
        type
      }
    }
  }
`;

export const FacebookConnectResponseData = gql`
  fragment FacebookConnectResponseData on FacebookConnectResponse {
    Name
    Picture
    AdAccounts {
      ...AdAccountData
    }
    Pages {
      ...PagesData
    }
  }
  ${AdAccountData}
  ${PagesData}
`;

export const MailchimpConnectResponseData = gql`
  fragment MailchimpConnectResponseData on MailchimpConnectResponse {
    Audiences {
      ...AudiencesData
    }
  }
  ${AudiencesData}
`;

export const GraphData = gql`
  fragment GraphData on Graph {
    uuid
    created
    name
    desc
    platformID
    platform
    hoursID
    modelID
    themeID
    ads {
      CampaignID
      AdSetID
      URL
    }
    nodes {
      ...NodeData
    }
    edges {
      ...EdgeData
    }
    pages {
      ...PagesData
    }
  }
  ${PagesData}
  ${NodeData}
  ${EdgeData}
`;

export const OK = gql`
  fragment OK on SuccessResponse {
    ID
    OK
  }
`;