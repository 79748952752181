export const QUERY_PARAMS = {
  CONNECT:               'connect',
  HOURS_ID:              'hoursId',
  ID:                    'id',
  MESSAGE:               'message',
  PATH:                  'path',
  QR_THEME_ID:           'qrThemeId',
  REAUTH:                'reAuth',
  THEME_ID:              'themeId',
  USERNAME:              'username',
  VENDASTA_ACCOUNT_ID:   'vAccountId',
  VENDASTA_ACCOUNT_ID_L: 'vendastaAccountId',
  VENDASTA_PLAN_ID:      'vPlanId',
  VENDASTA_PLAN_ID_L:    'vendastaPlanId',
  WEB_ID:                'webId'
};

export const extractQueryParams = (search) => {
  return {
    connect:    new URLSearchParams(search).get(QUERY_PARAMS.CONNECT),
    hoursId:    Number(new URLSearchParams(search).get(QUERY_PARAMS.HOURS_ID)),
    id:         new URLSearchParams(search).get(QUERY_PARAMS.ID),
    message:    new URLSearchParams(search).get(QUERY_PARAMS.MESSAGE),
    path:       new URLSearchParams(search).get(QUERY_PARAMS.PATH),
    qrThemeId:  Number(new URLSearchParams(search).get(QUERY_PARAMS.QR_THEME_ID)),
    reAuth:     Boolean(new URLSearchParams(search).get(QUERY_PARAMS.REAUTH)),
    themeId:    Number(new URLSearchParams(search).get(QUERY_PARAMS.THEME_ID)),
    username:   new URLSearchParams(search).get(QUERY_PARAMS.USERNAME),
    vAccountId: new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_ACCOUNT_ID) ? new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_ACCOUNT_ID) : new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_ACCOUNT_ID_L),
    vPlanId:    new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_PLAN_ID) ? new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_PLAN_ID) : new URLSearchParams(search).get(QUERY_PARAMS.VENDASTA_PLAN_ID_L),
    webId:      Number(new URLSearchParams(search).get(QUERY_PARAMS.WEB_ID))
  };
};