import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getPersonalInformation } from '../utils';
import { extractQueryParams, QUERY_PARAMS } from '../constants/query';

export const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [integration, setIntegration] = useState('');
  const [integrationError, setIntegrationError] = useState('');
  const [integrationPath, setIntegrationPath] = useState('');
  const [personalInfo, setPersonalInfo] = useState({});
  const [connect, setConnect] = useState('');
  const [hoursId, setHoursId] = useState(0);
  const [id, setId] = useState('');
  const [message, setMessage] = useState('');
  const [qrThemeId, setQrThemeId] = useState(0);
  const [reAuth, setReAuth] = useState(false);
  const [themeId, setThemeId] = useState(0);
  const [username, setUsername] = useState('');
  const [vAccountId, setVAccountId] = useState('');
  const [vPlanId, setVPlanId] = useState('');
  const [webId, setWebId] = useState(0);

  const history = useHistory();

  const clearIntegration = () => {
    setIntegration('');
    setIntegrationError('');
    setIntegrationPath('');
  };

  const getRedirectPath = () => {
    if (integrationPath) {
      return integrationPath;
    }
    return '/connect';
  };

  const processQueryParams = (search) => {
    const paramDict = extractQueryParams(search);

    Object.keys(paramDict).forEach(function(key) {
      const val = paramDict[key];
      switch(key) {
        case QUERY_PARAMS.CONNECT:
          setConnect(val);
          break;
        case QUERY_PARAMS.HOURS_ID:
          setHoursId(val);
          break;
        case QUERY_PARAMS.ID:
          setId(val);
          setVAccountId(val);
          break;
        case QUERY_PARAMS.MESSAGE:
          setMessage(val);
          break;
        case QUERY_PARAMS.PATH:
          setIntegrationPath(val);
          break;
        case QUERY_PARAMS.QR_THEME_ID:
          setQrThemeId(val);
          break;
        case QUERY_PARAMS.REAUTH:
          setReAuth(val);
          break;
        case QUERY_PARAMS.THEME_ID:
          setThemeId(val);
          break;
        case QUERY_PARAMS.USERNAME:
          setUsername(val);
          break;
        case QUERY_PARAMS.VENDASTA_ACCOUNT_ID:
          setVAccountId(val);
          break;
        case QUERY_PARAMS.VENDASTA_PLAN_ID:
          setVPlanId(val);
          break;
        case QUERY_PARAMS.WEB_ID:
          setWebId(val);
          break;
        default:
      }
    });
  };

  const shouldSignIn = (path) => {
    return path.indexOf('/auth/') < 0 && path.indexOf('/demo') < 0;
  };

  useEffect(() => {
    if (integration && integrationPath) {
      history.push(integrationPath);
    }
  }, [history, integration, integrationPath]);

  useEffect(() => {
    setPersonalInfo(getPersonalInformation());
  }, []);

  return (
    <SessionContext.Provider
      value={{
        clearIntegration,
        connect,
        getRedirectPath,
        hoursId,
        id,
        integration,
        integrationError,
        integrationPath,
        message,
        personalInfo,
        processQueryParams,
        qrThemeId,
        reAuth,
        setIntegration,
        setIntegrationError,
        setIntegrationPath,
        setVAccountId,
        setVPlanId,
        shouldSignIn,
        themeId,
        username,
        vAccountId,
        vPlanId,
        webId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
