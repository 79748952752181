import { v4 as uuid } from 'uuid';

export const USER_INPUT_PAGINATE_COUNT = 20;

export const options = {
  layout: {
    hierarchical: {
      enabled: true,
      direction: 'UD',
      sortMethod: 'directed',
      levelSeparation: 190,
      nodeSpacing: 250,
      shakeTowards: 'roots',
    },
  },
  edges: {
    arrowStrikethrough: false,
    chosen: {
      label: function(values) {
        values.size = 32;
        values.mod = 'bold';
        values.strokeWidth = 14;
      },
    },
    color: '#c8c8c8',
    smooth: false,
    width: 0.5,
    physics: false,
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 0.5,
      },
    },
    font: {
      color: '#000',
      size: 24,
      strokeColor: '#fff',
      strokeWidth: 10,
    },
    selectionWidth: 6,
  },
  nodes: {
    brokenImage: 'https://cdn.cashbot.ai/assets/sspimageerror.svg',
    color: {
      border: '#2B7CE9',
      background: '#fff',
      highlight: {
        border: '#2B7CE9',
        background: '#D2E5FF',
      },
      hover: {
        border: '#2B7CE9',
        background: '#D2E5FF',
      },
    },
    font: {
      color: '#343434',
    },
    widthConstraint: {
      maximum: 200,
    },
    fixed: { y: true },
  },
  groups: {
    text: {
      color: {
        background: '#B5F1D7',
      },
      margin: 20,
      shape: 'box',
    },
    ai: {
      color: {
        background: '#FFFFFF',
        border: 'rgba(0,0,0,0)',
      },
      size: 40,
      shape: 'image',
      image: 'https://cdn.cashbot.ai/assets/13_icon_AI.svg',
      imagePadding: { left: 8, top: 8, right: 9, bottom: 9 },
      label: '',
      shapeProperties: {
        useBorderWithImage: true,
        useImageSize: true
      },
    },
    quickreplies: {
      color: {
        background: '#438ADE',
      },
      font: {
        color: '#fff',
      },
      margin: 15,
      shape: 'box',
    },
    buttons: {
      font: {
        color: '#2076f2',
      },
      margin: 20,
      shape: 'box',
    },
    input: {
      color: {
        background: '#DCDCDC',
      },
      margin: 20,
      shape: 'box',
    },
    image: {
      shape: 'image',
    },
    transfer: {
      color: {
        background: '#ffffff',
        border: '#9b9b9b',
      },
      margin: 20,
      shape: 'box',
    },
    aialert: {
      shape: 'image',
      image: 'https://cdn.cashbot.ai/assets/13_ai_alert.svg',
      size: 30,
    },
    alert: {
      shape: 'image',
      image: 'https://cdn.cashbot.ai/assets/13_alert.svg',
      size: 30,
    },
    submit: {
      shape: 'image',
      image: 'https://cdn.cashbot.ai/assets/13_track.svg',
      size: 30,
      label: undefined,
    },
    card: {
      shape: 'image',
      image: 'https://cdn.cashbot.ai/assets/13_card.svg',
      size: 40,
    },
    video: {
      color: {
        border: '#979797',
      },
      image: 'https://cdn.cashbot.ai/assets/13_graph_video.svg',
      imagePadding: {
        left: 4,
      },
      label: undefined,
      size: 40,
      shape: 'circularImage',
      shapeProperties: {
        useImageSize: true,
        coordinateOrigin: 'center',
      },
    },
    label: {
      shape: 'box',
      color: {
        background: '#FFFF99',
      },
      margin: 20,
    },
  },
  manipulation: {
    enabled: false,
  },
  interaction: {
    hover: true,
  },
  physics: {
    hierarchicalRepulsion: {
      avoidOverlap: 0.6,
    },
  },
  autoResize: true,
};

export const cardButton = {
  label: '',
  meta: {
    categories: [],
    type: 'url',
    value: '',
    params: '',
    submit: 0,
  },
};

export const initCardFormData = {
  imageURL: '',
  title: '',
  description: '',
  buttons: [cardButton, cardButton, cardButton],
  cardURL: '',
  params: '0',
};

export const getUniqueCardFormData = () => ({
  ...initCardFormData,
  id: uuid(),
});

export const initConversionFormData = {
  url: '',
  type: 'POST',
  json: '{}',
  integration: 'custom',
  enrich: false,
  name: '',
  header0: {
    key: '',
    value: '',
  },
  header1: {
    key: '',
    value: '',
  },
  header2: {
    key: '',
    value: '',
  },
  header3: {
    key: '',
    value: '',
  },
  header4: {
    key: '',
    value: '',
  },
  header5: {
    key: '',
    value: '',
  },
  header6: {
    key: '',
    value: '',
  },
  header7: {
    key: '',
    value: '',
  },
  header8: {
    key: '',
    value: '',
  },
  header9: {
    key: '',
    value: '',
  },
};

export const initURL = {
  URL: '',
  WebID: '',
  Conditions: [{ Value: '', Type: '' }],
};

export const buttonData = {
  btn1: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
  btn2: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
  btn3: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
  btn4: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
  btn5: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
  btn6: {
    val: '',
    err: false,
    selectType: '',
    selectVal: '',
    selectCategories: [],
    selectParams: '',
    selectSubmit: 0,
    selectErr: false,
    selectErrMessage: '',
    willBeCreated: true,
  },
};
