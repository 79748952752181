import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Auth from '@aws-amplify/auth';

import { getAccountInfo, saveUser } from '../utils';
import { OnboardingContext } from './OnboardingContext';

import theme from '../theme';
import { redirectUrls } from '../theme/theme';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthed, setIsAuthed] = useState();
  const [checkAuth, setCheckAuth] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const client = useApolloClient();
  const location = useLocation();
  const { loadOnboarding } = useContext(OnboardingContext);

  const checkAuthStatus = useCallback(async () => {
    if (!checkAuth && !isChecking) {
      setIsChecking(true);
      setIsLoading(true);
      // reach out and get current authenticated user,  dont use cache
      const res = await Auth.currentAuthenticatedUser({ bypassCache: true })
        // if theres data, set it locally
        .then(data1 => {
          return loadOnboarding().then(() => {
            const acctInfo = getAccountInfo();
            if (acctInfo?.partnerUsername &&
              theme?.partner_username &&
              acctInfo.partnerUsername !== theme.partner_username &&
              redirectUrls?.[acctInfo.partnerUsername]) {
              window.location.href = redirectUrls[acctInfo.partnerUsername];
            } else {
              return setLocalData(data1);
            }
          });
        })
        // if there's no data/user return false
        .catch(err1 => {
          return false;
        });
      setCheckAuth(true);
      setIsChecking(false);
      setIsLoading(false);
      setIsAuthed(res ? true : false);
      return res ? true : false;
    }
    return isAuthed;
  }, [checkAuth, isAuthed, isChecking, loadOnboarding]);

  const init = useCallback(async () => {
    await checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (!checkAuth && !isChecking) {
      init();
    }

  }, [checkAuth, isChecking, init, location]);

  const setLocalData = data => {
    saveUser(data);
    setIsAuthed(true);
    return true;
  };

  const signinUser = async (user, doVendasta) => {
    setLocalData(user);
    await loadOnboarding();
    const location = localStorage.getItem('location');
    let search = localStorage.getItem('search');
    const accountSearch = localStorage.getItem('accountSearch');
    const onboardingType = localStorage.getItem('onboardingType');
    if (onboardingType) {
      await Auth.updateUserAttributes(user, {
        'custom:onboardingType': onboardingType
      });
    }

    let loc = '/';
    if (location) {
      localStorage.removeItem('location');
      if (search) {
        localStorage.removeItem('search');
        if (accountSearch) {
          search = accountSearch;
          localStorage.removeItem('accountSearch');
        }
        if (location && location.indexOf('auth/') === -1) {
          loc = location + search;
        }
      } else {
        if (location && location.indexOf('auth/') === -1) {
          loc = location;
        }
      }
    }

    window.location.href = loc;

    return true;
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(user, oldPassword, newPassword);

    return data === 'SUCCESS';
  };

  const logout = async (path, isLogin) => {
    // grab local copy of important setting from localstorage
    const settings = localStorage.getItem('dontShowPageModalForOne');
    const graph = localStorage.getItem('graph');
    // remove everything from localStorage
    localStorage.clear();
    // reset apollo client
    client.clearStore();
    // change nav to logged out state
    await Auth.signOut();
    // add important setting back to localstorage (persist through logout)
    if (settings === 'true') {
      localStorage.setItem('dontShowPageModalForOne', settings);
    }
    if (graph) {
      localStorage.setItem('graph', graph);
    }
    // push user to root

    setIsAuthed();
    setCheckAuth(false);
    setIsChecking(false);
    setIsLoading(false);
    if (!path?.indexOf || path.indexOf('/auth/signin') < 0) {
      window.location.href = '/auth/signin';
    }
  };

  useEffect(() => {
    if (!isChecking && isLoading) {
      setIsLoading(false);
    }
  }, [isChecking, isLoading, location]);

  return (
    <AuthContext.Provider
      value={{
        changePassword,
        isAuthed,
        isLoading,
        logout,
        signinUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used in AuthProvider`);
  }

  return context;
}

export { useAuth, AuthProvider };
